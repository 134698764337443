import React, { useEffect, useState } from "react";
import './revenue-simulator.css';
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { Trans } from "react-i18next";
import Info from "../../../theme/components/info";
import { getConfig as getAppConfig } from "../../../platforms/config";
import { use } from "i18next";
import { getConfig } from "../../app/app-slice";

const calculateRevenue = (price: number, duration: number, attendees: number, config: any, isPerMinute: boolean) => {
  if (config == undefined) return 0;
  let revenue = price * attendees;
  if (isPerMinute){
    revenue = price * duration * attendees;
  }
  const transactionFee = revenue * config.transactionFeePercent;
  
  let revenueWithoutCommission = revenue - transactionFee  - ((revenue-transactionFee) * config.commissionPercent) * 1000.0 /1000.0;
  return revenueWithoutCommission;
}

function superParseFloat(value: string) : number {
  return parseFloat(value.replace(",", "."));
}

function testDecimal(value: string) {
  var regexp = /^\d+([.,]\d{0,2})?$/;
  return regexp.test(value);
}

export default function RevenueSimulator() {
  const [duration, setDuration] = useState(30.0);
  const [price, setPrice] = useState("19.0");
  const [attendees, setAttendees] = useState(100);
  const [type, setType] = useState<string>("event");
  const dispatch = useAppDispatch();
  const config = useAppSelector((state) => state.app.config);
  useEffect(() => {
    dispatch(getConfig());
  }, []);
  
  useEffect(() => {
    setRevenue(calculateRevenue(parseFloat(price), duration, attendees, config, true));
  }, [config]);

  const [revenue, setRevenue] = useState(calculateRevenue(19, 30, 100, config, true));


  const updatePrice = (price: number, duration: number, attendees: number, isPerMinute: boolean, config: any) => {
    setRevenue(calculateRevenue(price, duration, attendees, config, isPerMinute));
  }




  useEffect(() => {
    updatePrice(parseFloat(price), duration, attendees, false, config);
  }, [config]);
  
  return <div className="RevenueSimulator">
    <span><Trans i18nKey="revenueSimulator.amount">Avec un tarif de </Trans><input type="text" value={price} onChange={ (event : React.ChangeEvent<HTMLInputElement>) => {
      

      const price = superParseFloat(event.target.value);
      let newValue = event.target.value;
      if (testDecimal(newValue) && price >= 0) {
        if (event.target.value.startsWith("0") && event.target.value.length > 1 && !event.target.value.startsWith("0.") && !event.target.value.startsWith("0,")) {
          newValue = event.target.value.substring(1);
        }
        setPrice(newValue);
        updatePrice(price, duration, attendees, type === "minute", config);
      }else {
        setPrice("0");
        updatePrice(0, duration, attendees, type === "minute", config);
      }
    }}></input> <Trans i18nKey="revenueSimulator.each">$ par</Trans> { getAppConfig().name == "weerlive" ? <select onChange={ (e) => {setType(e.target.value); updatePrice(superParseFloat(price), duration, attendees, e.target.value == "minute", config)}}><option value="event"><Trans i18nKey="revenueSimulator.event">évènement</Trans></option><option value="minute"><Trans i18nKey="revenueSimulator.minute">minute</Trans></option></select> : <Trans i18nKey="revenueSimulator.event">évènement</Trans>}</span> 
    <span>{type == "minute" && <><Trans i18nKey="revenueSimulator.live">pour un live de</Trans> <input value={duration} type="text" onChange={ (event : React.ChangeEvent<HTMLInputElement>) => {
      const duration = parseFloat(event.target.value);
      if (!isNaN(duration) && duration > 0) {
        setDuration(duration);
        updatePrice(superParseFloat(price), duration, attendees, type === "minute", config);
      }else {
        setDuration(0);
        updatePrice(superParseFloat(price), 0, attendees, type === "minute", config);
      }
    }}></input> <Trans i18nKey="revenueSimulator.minutes">minutes</Trans></>} <Trans i18nKey="revenueSimulator.with">with</Trans> <input type="text" value={attendees} onChange={(event : React.ChangeEvent<HTMLInputElement>) => {
      const attendees = parseInt(event.target.value);
      
      if (!isNaN(attendees) && attendees > 0) {
        setAttendees(attendees);
        updatePrice(superParseFloat(price), duration, attendees, type === "minute", config);
      }else {  
        setAttendees(0);
        updatePrice(superParseFloat(price), 0, attendees, type === "minute", config);
  
      }
    }}></input> <Trans i18nKey="revenueSimulator.viewers">participants</Trans></span>
    <span> <Trans i18nKey="revenueSimulator.receive">recevez</Trans> <span className="attention">{revenue.toFixed(2)} $</span><Info style={{top: -20}} message="(Amount excl. VAT - operator's fees) x 80%" /></span>
  </div>;
}