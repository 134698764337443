export enum ValidationStatus {
    Todo = "todo",
    Pending = "pending",
    Validated = "validated",
    Refused = "refused"
}
  
export enum IdentityValidationState {
    Todo = "todo",
    Pending = "pending",
    Review = "review",
    Validated = "validated",
    Resubmit = "resubmit",
    Refused = "refused"
  }