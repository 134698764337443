import { ChatData } from "./dto/chat-message.dto";
import CreatorImage from "../creator/components/creator-image";
import React, { useEffect, useRef, useState } from "react";

import api from "../../common/api";

import { useNavigate, useParams } from "react-router-dom";


import { EventDTO, EventPaymentType, EventState } from "../event/dto/event.dto";
import { useAppSelector } from "../../hooks";

import './studio.css';

import { Socket } from "socket.io-client";
import { Trans } from "react-i18next";

import { io } from "socket.io-client";
import BASE_URL from "../../common/urls";
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { ConnectedUser } from "./dto/connected-user.dto";
import Timer from "./components/timer";
import logger from "../../common/logger";
import { RightBar } from "./components/right-bar";
import BottomBar from "./components/bottom-bar";
import VideoGallery from "./components/video-gallery";
import IVSContext from "./context/ivs-context";
import MediaContext from "./context/media-context";
import SocketContext from "./context/socket-context";

import useModal from "../../theme/hooks/use-modal";

import { getPlaformImage } from "../../platforms/config";
import { t } from "i18next";
import { set } from "lodash";
import moment, { Moment } from "moment";
import ivsContext from "./context/ivs-context";
import { createLocalStageStream, joinStage, leaveStage } from "./stageUtils";
import { CAMERA, DeviceType, getDevices, MIC } from "./mediaDevices";

const logo = getPlaformImage("logo.svg");
const launch = getPlaformImage('studio/launch.png');
const dots = getPlaformImage('more-options.svg');
  

function CountDownTimer({expiration} : {expiration: Moment}){
    const [secondsRemaining, setSecondsRemaining] = useState<number | undefined>();
    useEffect(() => {
        const intervalID = setInterval(() => {
            const diff = moment(expiration).diff(moment(), 'seconds');
            setSecondsRemaining(diff);
            if (diff <= 0){
                clearInterval(intervalID);
            }
        }, 1000);
        return () => {
            clearInterval(intervalID);
        }
    }, [expiration]);
    return <>{secondsRemaining}</>;
}

export default function Studio(){


    const [isInitializeComplete, setIsInitializeComplete] = useState(false);

    const [videoDevices, setVideoDevices] = useState([]); 
    const [audioDevices, setAudioDevices] = useState([]); 
    const [selectedVideoDeviceId, setSelectedVideoDeviceId] =  useState<string | null>(null);
    const [selectedAudioDeviceId, setSelectedAudioDeviceId] = useState<string | null>(null); 
    const [isConnected, setIsConnected] = useState(false); 
    const [participants, setParticipants] = useState<any[]>([]); 
    const stageRef = useRef<any>();
    const strategyRef = useRef<any>();
    
    const [connected, setConnected] = useState(false);
    const [micIsMuted, setMicIsMuted] = useState(false);
    const [camIsVisible, setCamIsVisible] = useState(false);

    const [ready, setReady] = useState(false);
    const modal = useModal();
    const [cameras, setCameras] = useState<MediaDeviceInfo[]>([]);
    const [microphones, setMicrophones] = useState<MediaDeviceInfo[]>([]);

    const mediaStream = useRef<any | undefined>(); 
    
    
    const [chatMessages, setChatMessages] = useState<ChatData[]>([]);

    const [me, setMe] = useState<ConnectedUser | undefined>(undefined);
    const [users, setUsers] = useState<ConnectedUser[]>([])
    const [event, setEvent] = useState<EventDTO | undefined>()

    const navigate = useNavigate(); 
    const user = useAppSelector((state) => state.login.user);
    const token = useAppSelector((state) => state.login.token);

    const [dataToken, setDataToken] = useState<string | undefined>();
    const [videoToken, setVideoToken] = useState<string | undefined>();

    const socket = useRef<Socket | undefined>();
    const [secondsRemaining, setSecondsRemaining] = useState<number | undefined>();

    const updateLocalParticipantMedia = async (localParticipant: any) => {
        const { participant } = localParticipant;
    
        // Create new local streams
        const newVideoStream = await createLocalStageStream(
          selectedVideoDeviceId!,
          DeviceType.CAMERA
        );
        const newAudioStream = await createLocalStageStream(
          selectedAudioDeviceId!,
          DeviceType.MIC
        );
    
        // Update the streams array with the new streams
        const updatedStreams = [newVideoStream, newAudioStream];
    
        const updatedParticipant = {
          participant,
          streams: updatedStreams,
        };
    
        // Find the index of the local participant
        const localParticipantIndex = participants?.findIndex(
          (participant) => participant.participant.isLocal
        );
    
        const updatedParticipants = [...participants];
    
        // Replace the participant at the specified index with the updated participant
        updatedParticipants[localParticipantIndex] = updatedParticipant;
    
        setParticipants([...updatedParticipants]);
    
        strategyRef.current?.updateTracks(newAudioStream, newVideoStream);
        stageRef.current?.refreshStrategy();
      };

    useEffect(() => {
        //Check to ensure that the stage and the strategy have completed initialization
        const isInitializingStreams =
          !strategyRef.current?.audioTrack && !strategyRef.current?.videoTrack;
        if (!isInitializeComplete || isInitializingStreams) return; // If initialization is not complete, return
    
        const localParticipant = participants.find(
          (participant) => participant.participant.isLocal
        );
    
        if (localParticipant.streams) {
          //updateLocalParticipantMedia(localParticipant);
        }
      }, [selectedVideoDeviceId, selectedAudioDeviceId]);

    const startInviteTimer = () => {
        
        const expiration = moment().add(30, 'seconds');
        let intervalID : NodeJS.Timeout | undefined;
        intervalID = setInterval(() => {
            console.log("Updating timer");
            const diff = expiration.diff(moment(), 'seconds');
            setSecondsRemaining(diff);
            if (diff <= 0){
                clearInterval(intervalID);
                modal.show(t("studio.modals.invite_expired.title"), <>{t("studio.modals.invite_expired.content")}</>, [{
                    label: t("studio.modals.invite_expired.button_ok"),
                    onClick: () => {
                    }
                }]);
                
                return false;
            }
            setSecondsRemaining(moment().diff(expiration!, 'seconds'));
        }, 1000);

        return intervalID;
    }
 


    const onNewChatMessage = (payload: ChatData) => {
        setChatMessages([...chatMessages, payload]);
    };

    let { id } = useParams();
    const [ waitPage , setWaitPage ] = useState(true);
    
    const [ recordEnabled, setRecordEnabled ] = useState<boolean>(false);
    const [ vodPrice, setVodPrice ] = useState<number>(0);

    const updateDevices = () => {
        
        
        /*ZoomVideo.getDevices().then( (devices) => { 
            const videoDevices = devices.filter((device) => {
                return device.kind === 'videoinput'
            })

            const audioDevices = devices.filter((device) => {
                return device.kind === 'audioinput'
              })
            console.log("Camera list :");
            console.log(videoDevices);
            console.log("Microphone list :");
            console.log(audioDevices);

            setCameras(videoDevices);
            setMicrophones(audioDevices);
        });*/
    };
    

    useEffect(() => {
        if (id && dataToken){
            if (socket.current){
                socket.current?.disconnect();
            }
            api.get(`/events/${id}`).then((response) => {
                if (event && event.isOwner || event?.isSubscribed || user?.isAdmin){
                
                    socket.current = io(BASE_URL, {
                        extraHeaders: {
                            Authorization: `Bearer ${dataToken}`
                        }
                    });

                    socket.current.on("admin-message", (message: string) => {
                        modal.show(t("studio.modals.message.title"), <>{message}</>, [{
                            label: t("studio.modals.message.button_ok"),
                            onClick: () => {

                            }
                        }]);

                    }); 
                    socket.current.on("me", async (user: ConnectedUser) => {
                        console.log("User updated :", user)
                        setMe(user);
                        if (strategyRef.current){
                            await strategyRef.current.updateMe(user.role == 2, user.invited);
                            if (user.invited == false && user.role != 2){
                                stageRef.current?.refreshStrategy();
                            }
                            stageRef.current.refreshStrategy();
                        }
                    }); 
                    socket.current.on("report", (user: ConnectedUser) => {
                        modal.show(t("studio.modals.report.title"), <>{t("studio.modals.report.content")}</>, [{
                            label: t("studio.modals.report.button_yes"),
                            onClick: () => {

                            }
                        }]);
                    }); //"Proposition de participer à l'évènement"
                    socket.current.on("user-invite-request", () => {
                        const inviteTimeout = startInviteTimer();
                        
                        modal.show(t("studio.modals.invite.title"), <>
                        {t("studio.modals.invite.content")} : <CountDownTimer expiration={moment().add(30, "seconds")} /> {t("studio.modals.invite.seconds_remains")} </>, [{
                                label: t("studio.modals.invite.button_yes"),
                                onClick: () => {
                                    socket.current?.emit("user-invite-accepted")
                                    clearTimeout(inviteTimeout);
                                }
                            },
                            {
                                label: t("studio.modals.invite.button_no"),
                                onClick: () => {
                                    socket.current?.emit("user-invite-declined")
                                    clearTimeout(inviteTimeout);
                                }
                            }
                        ]);
                    });
                    socket.current.on("user-unpromote", () => {
                        modal.show(t("studio.modals.unpromote.title"), <>{t("studio.modals.unpromote.content")}</>, [{
                                    label: t("studio.modals.unpromote.button_ok"),
                                onClick: () => {
                                }
                            }
                        ]);
                    });

                    socket.current.on("user-uninvited", () => {
                        modal.show(t("studio.modals.uninvited.title"), <>{t("studio.modals.uninvited.content")}</>, [{
                                label: t("studio.modals.uninvited.button_ok"),
                                onClick: () => {
                                }
                            }
                        ]);
                    });                    
                    socket.current.on("user-promote-request", () => {
                        modal.show(t("studio.modals.promote.yes"), <>{t("studio.modals.promote.content")}</>, [{
                                label: t("studio.modals.promote.button_yes"),
                                onClick: () => {
                                    socket.current?.emit("user-promote-accepted")
                                }
                            },
                            {
                                label: t("studio.modals.promote.button_no"),
                                onClick: () => {
                                    socket.current?.emit("user-promote-declined")

                                }
                            }
                        ]);
                    });

                    socket.current.on("user-already-connected", (user: ConnectedUser) => {
                        modal.show(t("studio.modals.connected.title"), <>${t("studio.modals.connected.content")}</>, [{
                            label: t("studio.modals.connected.button_ok"),
                            onClick: () => {
                                socket.current?.disconnect();
                                navigate("/");
                            }
                        }]);
                    });
                    socket.current.on("user-ban", (user: ConnectedUser) => {
                        setWaitPage(true);
                        modal.show(t("studio.modals.banned.title"), <>{t("studio.modals.banned.content")}</>, [{
                            label: t("studio.modals.banned.button_ok"),
                            onClick: () => {
                                socket.current?.disconnect();
                                navigate("/");
                            }
                        }]);
                    });
                    socket.current.on("user-alert", () => {
                        modal.show(t("studio.modals.alert.title"), <>{t("studio.modals.alert.content")}</>, [{
                            label: t("studio.modals.alert.button_ok"),
                            onClick: () => {

                            }
                        }]);
                    });
                    socket.current.on("connect", () => {
                    });
                    socket.current.on("disconnect", () => {
                        socket.current?.disconnect();
                        /*zoomClient.current?.leave().then(() => {
                            navigate("/");
                        });*/
                    });
                    setEvent(response.data); 
                }

            }).catch((error) => {
                console.log(error);
            });
        }
        return () => {
            socket.current?.disconnect();
            /*zoomClient.current?.leave().then(() => {
                logger.info("Leaving video session");
            }).catch((error) => {
                logger.error("Error while leaving video session", error);
            });*/
        };
    }, [id, user, dataToken]);


    useEffect(() => {
        if (id && user && waitPage){
            logger.info(`Retrieving event data for event ${id}`);

            api.get(`/events/${id}/token`).then((response) => {
                logger.info(`Event data sucessfully retrieved ${id}`);

                setDataToken(response.data.token);
                setVideoToken(response.data.videoToken);
                setEvent(response.data.event);
            }  
        ).catch((error) => {
            logger.error(`Error while fetching event token ${error}`);
        });
        }
    }, [id, user, waitPage]);

    useEffect(() => {
        if (event && event.state == EventState.Done && !event.isOwner){
            modal.show(t("studio.modals.event_done.title"), <>{t("studio.modals.event_done.content")}</>, [{
                label: t("studio.modals.event_done.button_ok"),
                onClick: () => {
                    navigate("/");
                }
            }]);
        }
        if (event && (event.state != EventState.Draft && event.state != EventState.Live && event.state != EventState.Published && (event.state != EventState.Done || event.isOwner))){
            navigate(`/`);
        }
        if (event && event.isOwner){
            setWaitPage(false);
        }else if (event && !user?.isAdmin && !event?.isSubscribed){
            navigate(`/event/${id}/checkout`);
        }else if (event && (user?.isAdmin  || event.isSubscribed && event.state == EventState.Live)) {
            setWaitPage(false);
        }

        const onEventUpdate = (newEvent : any) => {
            if (id == newEvent.shortId){

                if (event){
                    setEvent({ ...event!, state: newEvent.state, liveStartedAt: newEvent.liveStartedAt})
                }
            }
        }

        
        
        if (socket.current){
            socket.current.on("event", onEventUpdate);
        }   

    


        return () => {
            socket.current && socket.current.off("event", onEventUpdate);
        }

    }, [event]);

    useEffect(() => {
        if (videoToken && me && !isConnected){

            joinStage(true, me, videoToken, selectedVideoDeviceId, selectedAudioDeviceId, setIsConnected, setMicIsMuted, setParticipants, stageRef, strategyRef);
        }


 
    }, [videoToken, me, isConnected]);

    useEffect(() => {
    
        setWaitPage(!isConnected)

    }, [isConnected]);


    useEffect(() => {
        if (isConnected){

            socket.current?.emit("get-users", (users : ConnectedUser[]) => {
                console.log(users);
                setUsers(users);
            });
            setReady(true);
        }
    }, [isConnected]);

    useEffect(() => {
        getDevices().then((devices) => {
            setCameras(devices.videoDevices);
            setMicrophones(devices.audioDevices);
            setSelectedAudioDeviceId(devices.audioDevices[0].deviceId);
            setSelectedVideoDeviceId(devices.videoDevices[0].deviceId);  
        });

    },[]);


    useEffect(() => {
        socket.current?.on("chat-message", onNewChatMessage);
        return () => {
            socket.current?.off("chat-message", onNewChatMessage);
        }
    }, [socket.current, chatMessages]);

    const onNewUser = (payload: ConnectedUser) => {
        setUsers([...users, payload]); 
    }


    const onUpdatedUser = (payload: ConnectedUser) => {
        logger.info(`User updated ${payload.hand}`);
        const newUsers = users.map((user) => {
            if (user.id == payload.id){
                logger.info(`User found ${payload.id}`);

                return payload;
                
            }
            return user;
        });
        setUsers(newUsers);
    }
    
    const onLeaveUser = (payload: ConnectedUser) => {
        console.log("User left");
        const newUsers = users.filter((user) => {
            return user.id != payload.id;
        });
        setUsers(newUsers);
    }

    


    useEffect(() => {
        socket.current?.on("user-added", onNewUser);
        socket.current?.on("user-leave", onLeaveUser);
        socket.current?.on("user-updated", onUpdatedUser);
        

        
        return () => {
            socket.current?.off("user-added", onNewUser);
            socket.current?.off("user-leave", onLeaveUser);
            socket.current?.off("user-updated", onUpdatedUser);


        }
    }, [users]);

    
    const [showStartModal, setShowStartModal] = useState(false);
    const [showWelcomeModal, setShowWelcomeModal] = useState(true);


    return <>

            {waitPage && <div style={{display: "flex", flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <h1 style={{color: "#DFDFDF", fontSize: 16}}>Waiting for the event to start</h1>
            </div> }

            
            <Modal centered={true}  isOpen={ready && showWelcomeModal} style={{ border: 0, borderRadius: 50}}>
                <ModalHeader style={{backgroundColor: '#131015',  border: 0}}>
                    { event?.isOwner && <Trans i18nKey={"studio.modals.join_event.creator_title"}>Rejoindre l'événement Creator</Trans> }
                    { !event?.isOwner && <Trans i18nKey={"studio.modals.join_event.viewer_title"}>Rejoindre l'événement Viewer</Trans> }
                </ModalHeader>
                <ModalBody style={{backgroundColor: '#131015', border: 0 }}>
                    { event && <div style={{display: "flex", flexDirection: "row", marginBottom: 30}}>
                    { event.isOwner && <><div style={{display: "flex", alignItems:"flex-start"}}>
                    { !event?.isOwner &&  <CreatorImage userName={event.owner.userName} style={{maxHeight: 50}} image={event.owner.picture} color={event.owner.color} /> }
                       
                    </div>
                    <div style={{textAlign: "center"}}>
                        <p style={{fontWeight: "bold", textTransform: "uppercase"}}><Trans i18nKey={"studio.modals.join_event.first_title"}>Tips :</Trans></p>
                        <p><Trans i18nKey={"studio.modals.join_event.first_part"}>Use the</Trans> <img src={dots} style={{height: "20px", marginLeft: "5px", marginRight: "5px"}}/> <Trans i18nKey={"studio.modals.join_event.second_part"}>icon to interact with a viewer. For example: Invite to participate, promote to moderator, send an alert and ban.</Trans></p>
                        <p style={{fontWeight: "bold", textTransform: "uppercase", marginTop: 50}}><Trans i18nKey={"studio.modals.join_event.second_title"}>2 more steps to start :</Trans></p>
                        <p><Trans i18nKey={"studio.modals.join_event.first_point"}>1/ Activate your camera and mic</Trans></p>
                        <p><Trans i18nKey={"studio.modals.join_event.second_point"}>2/ Clic on</Trans> <img src={launch} style={{height: "20px"}}/> <Trans i18nKey={"studio.modals.join_event.third_point"}>to launch the live</Trans></p>
                    
                       
                    </div></> }
                    { !event.isOwner && <><div style={{marginRight: 10, display: "flex", alignItems:"flex-start"}}>
                        <CreatorImage userName={event.owner.userName} style={{maxHeight: 50}} image={event.owner.picture} color={event.owner.color} />
                    </div>
                    <div>
                        <h2 style={{color: "#8A8A8A", fontSize: 14}}>{event.owner.userName}</h2>
                        <h1 style={{color: "#DFDFDF", fontSize: 16}}>{event.title}</h1>
                        <p style={{color: "#8A8A8A", fontSize: 16}}>{event.description.substring(0, 200)} {event.description.length > 200 && "..." }</p>
                    </div></> }                    
                </div> }
                </ModalBody>
                <ModalFooter style={{backgroundColor: '#131015',  border: 0}}>
                    <Button color="primary" onClick={ () => {
                        setShowWelcomeModal(false);
                    }}><Trans i18nKey={"studio.modals.join_event.button"}>Rejoindre l'événement</Trans></Button>
                </ModalFooter>
            </Modal> 
            <Modal centered={true} isOpen={showStartModal} style={{ border: 0, borderRadius: 50}}>
                <ModalHeader style={{backgroundColor: '#131015',  border: 0}}>
                    {event?.state !== EventState.Live && <Trans i18nKey={"studio.modals.start_event.title"}>Souhaitez-vous démarrer l'événement</Trans> }
                    {event?.state === EventState.Live && <Trans i18nKey={"studio.modals.stop_event.title"}>Souhaitez-vous arrêter l'événement</Trans> }

                </ModalHeader>
                <ModalBody style={{backgroundColor: '#131015', border: 0, textAlign:"center" }}>
                    { event && <div style={{}}>

                    { event.state !== EventState.Live && event.isPublic && <div>
                        <FormGroup>
                        <p><Trans i18nKey={"studio.modals.start_event.description"}></Trans></p>
                        <input style={{marginRight: 10}} type="checkbox" id="record" checked={recordEnabled} onChange={ () => {
                                setRecordEnabled(!recordEnabled);
                            }} />
                            <Label for="record"><Trans i18nKey={"studio.modals.start_event.record"}>Enregistrer l'événement pour le vendre en replay</Trans></Label>                  
                        </FormGroup>
                        { recordEnabled && event.paymentType == EventPaymentType.Time && <FormGroup>
                            <Label for="vodPrice"><Trans i18nKey={"studio.modals.start_event.price"}>Prix</Trans></Label>
                            <input type="number" id="price" checked={recordEnabled} value={vodPrice} onChange={ (e) => {
                                setVodPrice(parseInt(e.target.value) as number);
                            }} /> $
                        </FormGroup>}
                        </div>
                    }
                    
                </div> }
                </ModalBody>
                <ModalFooter style={{backgroundColor: '#131015',  border: 0}}>
                    <Button color="primary" onClick={ () => {
                        if (event?.state !== EventState.Live){
                            socket.current?.emit("start-event", {record: recordEnabled, eventID: event?._id, price: recordEnabled ? vodPrice : undefined});
                            if (recordEnabled){
                                /*zoomClient.current?.getRecordingClient().startCloudRecording().then(() => {
                                    logger.info("Recording started");
                                }).catch((error: any) => {
                                    logger.error("Error while starting recording", error);
                                });*/
                            }
                        }else {
                            /*zoomClient.current?.getRecordingClient().stopCloudRecording().then(() => {
                                logger.info("Recording stopped");
                            }).catch((error: any) => {
                                logger.error("Error while stopping recording", error);
                            });*/
                            socket.current?.emit("stop-event", { eventID: event?._id });

                        }
                        setShowStartModal(false);
                    }}>{ event?.state == EventState.Live ? <Trans i18nKey={"studio.modals.stop_event.button"}>Start</Trans> : <Trans i18nKey={"studio.modals.start_event.button"}>Start</Trans> } </Button>
                    <Button color="primary" onClick={ () => {
                        setShowStartModal(false);
                    }}><Trans i18nKey={"studio.modals.start_event.button_later"}>Plus tard</Trans></Button>                    
                </ModalFooter>
            </Modal> 

            {ready && socket.current && <SocketContext.Provider value={socket.current}><div style={{display: "flex",  flex: 1, flexDirection: 'column', }}>
            <div className="studio-top-bar-left">
                <div style={{display: 'flex', flex: 6, justifyContent: 'space-between'}}>
                    <img src={logo} style={{marginLeft: '10px', height: '30px'}} />
                    { event?.liveStartedAt && <Timer liveStartedAt={event?.liveStartedAt} viewers={users.filter((value) => {return !value.admin && value.role!=2}).length}/> }
                </div>
                <div className="studio-top-bar-right">

                </div>
            </div>
            <div className="studio-pane">
                <div className="studio-main">
                            {event && me && stageRef.current && <IVSContext.Provider value={stageRef.current}>
                                    {participants && <VideoGallery participants={participants} event={event} me={me} setShowStartModal={setShowStartModal} users={users} chatMessages={chatMessages}/>}
                            </IVSContext.Provider> }
                    <RightBar className="studio-desktop-right-bar" users={users} me={me!} chatMessages={chatMessages} chatEnabled={event ? event.chatEnabled : false} socket={socket.current!} />
                </div>
                {event && <BottomBar className="studio-desktop-button-bar" event={event} /> }

            </div>

        </div> </SocketContext.Provider>}
    </>;
}