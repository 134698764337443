import { Form, Link, useNavigate } from "react-router-dom";
import FormInput from "../../../theme/components/form-input";
import { Alert, Button, Col, FormFeedback, FormGroup, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import * as yup from "yup"
import { useAppSelector, useYupValidationResolver } from "../../../hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import { CertifyProfilSchema } from "./validate-profil-form";
import { Trans } from "react-i18next";
import { t } from "i18next";
import { INSTAGRAM_PREFIX, TIKTOK_PREFIX, TWITTER_PREFIX, YOUTUBE_PREFIX, LINKEDIN_PREFIX } from "../../../common/social";
import { getConfig } from "../../../platforms/config";

interface CertifyProfileProps {
    onSubmit?: SubmitHandler<CertifyProfile>;
    error?: string;
    submitLabel: String;
}

export type CertifyProfile = {

    twitter?: string,
    instagram?: string,
    tiktok?: string,
    youtube?: string,
    linkedin? : string
  }
  
  const CreateProfilValidationSchema = yup.object({
    twitter: yup.string().label(t("certify_profil.twitter")).notRequired(),
      youtube: yup.string().label(t("certify_profil.youtube")).notRequired(),
      instagram: yup.string().label(t("certify_profil.instagram")).notRequired(),
      tiktok: yup.string().label(t("certify_profil.titkok")).notRequired(),
      linkedin: yup.string().label(t("certify_profil.linkedin")).notRequired()
  })
  

export default function CertifyProfilForm(props: CertifyProfileProps){
    const resolver = useYupValidationResolver(CreateProfilValidationSchema)
    const { register: _register, handleSubmit, formState: { errors } } = useForm<CertifyProfile>({resolver})
    const navigation = useNavigate();
    const categories = useAppSelector((state) => state.events.categories);

    return <>
            <p><Trans i18nKey={"certify_profil.requirements"}>1 compte minimum à saisir</Trans></p>
            <Form method="post" onSubmit={props.onSubmit && handleSubmit(props.onSubmit)} style={{display: "flex", flexDirection:"column", flex: 1, justifyContent: "space-evenly"}}>
                        <Row>
                        { props.error && <Alert color="danger">{props.error}</Alert> }  
              <Col md={12}>
              <FormGroup>
                <Label for="formTwitter">
                      <Trans i18nKey="certify_profil.twitter">Twitter</Trans>
                    </Label>
                    <InputGroup>
                    <InputGroupText style={{fontSize: 14}}>{TWITTER_PREFIX}</InputGroupText>
                    <FormInput
                      id="formTwitter"
                      type="text"
                      placeholder={t("certify_profil.twitter")}
                      register={_register}
                      name="twitter"
                      invalid={errors.twitter}>
                    </FormInput>         
                    </InputGroup>
                    <FormFeedback>
                      { errors.twitter?.message }
                   </FormFeedback>           
                  </FormGroup> 

              </Col>
              <Col md={12}>
              <FormGroup>
                <Label for="formYoutube">
                      <Trans i18nKey="certify_profil.youtube">Youtube</Trans>
                    </Label>
                    <InputGroup>
                    <InputGroupText style={{fontSize: 14}}>{YOUTUBE_PREFIX}</InputGroupText>
                    <FormInput
                      id="formYoutube"
                      type="text"
                      placeholder={t("certify_profil.youtube")}
                      register={_register}
                      name="youtube"
                      invalid={errors.youtube}>
                    </FormInput>
                    </InputGroup>               
                    <FormFeedback>
                      { errors.youtube?.message }
                    </FormFeedback>     
                  </FormGroup> 
              </Col>              
              <Col md={12}>
              <FormGroup>
                  <Label for="formInstagram">
                    <Trans i18nKey="certify_profil.instagram">Instagram</Trans>
                  </Label>
                  <InputGroup>
                    <InputGroupText style={{fontSize: 14}}>{INSTAGRAM_PREFIX}</InputGroupText>                  
                  <FormInput
                    id="formInstagram"
                    type="text"
                    placeholder={t("certify_profil.instagram")}
                    register={_register}
                    name="instagram"
                    invalid={errors.instagram}>
                  </FormInput>
                  </InputGroup>
                  <FormFeedback>
                      { errors.instagram?.message }
                    </FormFeedback>                    
                </FormGroup> 
              { getConfig().name === "blush" && 
              <FormGroup>
                  <Label for="formTiktok">
                    <Trans i18nKey="certify_profil.tiktok">Tiktok</Trans>
                  </Label>
                  <InputGroup>
                    <InputGroupText style={{fontSize: 14}}>{TIKTOK_PREFIX}</InputGroupText>                                    
                  <FormInput
                    id="formTiktok"
                    type="text"
                    placeholder={t("certify_profil.tiktok")}
                    register={_register}
                    name="tiktok"
                    invalid={errors.tiktok}>
                  </FormInput> 
                  </InputGroup>
                  <FormFeedback>
                      { errors.tiktok?.message }
                    </FormFeedback>                   
                </FormGroup> }
                { getConfig().name === "weerlive" && <FormGroup>
                  <Label for="formLinkedin">
                    <Trans i18nKey="certify_profil.linkedin">Linkedin</Trans>
                  </Label>
                  <InputGroup>
                    <InputGroupText style={{fontSize: 14}}>{LINKEDIN_PREFIX}</InputGroupText>                                    
                  <FormInput
                    id="formLinkedin"
                    type="text"
                    placeholder={t("certify_profil.linkedin")}
                    register={_register}
                    name="linkedin"
                    invalid={errors.linkedin}>
                  </FormInput> 
                  </InputGroup>
                  <FormFeedback>
                      { errors.linkedin?.message }
                    </FormFeedback>                   
                </FormGroup> }
              </Col>              
            </Row>
            <div>
            <Button type="submit" color="primary">
              { props.submitLabel }
            </Button>
            </div>
        </Form></>
}