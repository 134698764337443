import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { ConnectedUser } from "../dto/connected-user.dto";
import { useState } from "react";
import { Trans } from "react-i18next";


import more from "../../../assets/more-options.svg"
import { t } from "i18next";
import { getConfig } from "../../../platforms/config";

export interface UserWidgetProps{
    user : ConnectedUser
    me: ConnectedUser
    

    onClickPromote?: Function;
    onClickInvite?: Function;
    onClickAlert?: Function;
    onClickBan?: Function;
}


export function UserActionDropdown(props: UserWidgetProps){
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

    return <>{ props.me.role > 0 && props.me.role > props.user.role &&
        <Dropdown isOpen={dropdownOpen} toggle={toggle} style={{justifySelf: 'flex-end'}}>
            <DropdownToggle size="md" style={{background: "transparent"}}>
              <img src={more} />
            </DropdownToggle>
            <DropdownMenu>
              { props.me.role == 2 && <DropdownItem onClick={ ( e ) => props.onClickPromote && props.onClickPromote(props.user) }>{ props.user.role >0 ? t("studio.right_bar.unpromote") : t("studio.right_bar.promote") }</DropdownItem> }
              { getConfig().name !== "blush" && props.me.role == 2 && <DropdownItem onClick={ ( e ) => props.onClickInvite && props.onClickInvite(props.user) }>{ props.user.invited ? t("studio.right_bar.uninvite") : t("studio.right_bar.invite")}</DropdownItem> }
              <DropdownItem onClick={ ( e ) => props.onClickAlert && props.onClickAlert(props.user) }><Trans i18nKey={"studio.right_bar.alert"}>Send alert</Trans></DropdownItem>
              <DropdownItem onClick={ ( e ) => props.onClickBan && props.onClickBan(props.user) }><Trans i18nKey={"studio.right_bar.ban"}>Ban user</Trans></DropdownItem>          
            </DropdownMenu>
          </Dropdown> } </>
}