import { Form, Link, useNavigate } from "react-router-dom";
import FormInput from "../../../theme/components/form-input";
import { Alert, Button, Col, FormFeedback, FormGroup, Label, Row } from "reactstrap";
import * as yup from "yup"
import { useYupValidationResolver } from "../../../hooks";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import countryList from 'react-select-country-list'
import { useMemo } from "react";
import { Trans } from "react-i18next";
import { t } from "i18next";
import { I18nLink } from "../../../theme/components/I18nLink";
import { userNameValidator } from "../../../common/validators";
import { getConfig } from "../../../platforms/config";

interface CreateProfilProps {
    onSubmit?: SubmitHandler<CreateProfil>;
    error?: string;
    submitLabel: String;
}

export type CreateProfil = {

    country: string,
    userName: string,
    email: string,
    password: string,
    confirmPassword: string,
    checkCGU: boolean
    isOlderThan18?: boolean
  }
  
  let formValidationSchema = {
    country: yup.string().required(),
    userName: userNameValidator,
    email: yup.string().email().required(),
    password: yup.string().required(),
    confirmPassword: yup.string().required().oneOf([yup.ref('password')], 'Passwords must match'),
    checkCGU: yup.boolean().oneOf([true],'You must accept the terms and conditions')
  } as any

  if (getConfig().name === "blush") {
    formValidationSchema = {
      ...formValidationSchema,
      isOlderThan18: yup.boolean().oneOf([true], t('form.must_certify_older_than_18'))
    }
  }

  const CreateProfilValidationSchema = yup.object(formValidationSchema)
  

export default function CreateForm(props: CreateProfilProps){
    const resolver = useYupValidationResolver(CreateProfilValidationSchema)
    const { register: _register, handleSubmit, formState: { errors }, control  } = useForm<CreateProfil>({resolver})
    const navigation = useNavigate();
    const countries = useMemo(() => countryList().getData(), [])

    return <Form method="post" onSubmit={props.onSubmit && handleSubmit(props.onSubmit)} style={{display: "flex", flexDirection:"column", flex: 1, justifyContent: "space-evenly"}}>
            <Row>
              { props.error && <Alert color="danger">{props.error}</Alert> }  
              <Col md={12}>
              <FormGroup>
                <Label for="formCountry">
                    <Trans i18nKey="form.country">Pays</Trans>
                  </Label>
                    <FormInput
                      id="formCountry"
                      placeholder="Country"
                      type="select"
                      register={_register}
                      name="country"
                      invalid={errors.country}
                      style={{backgroundColor: "white"}}
                      defaultValue="FR"
                    >
                      { countries.map((country) => {
                        return <option key={country.value} value={country.value}>{country.label}</option>
                      })}
                    </FormInput>
                    <FormFeedback>
                      { errors.country?.message }
                    </FormFeedback>                       
                </FormGroup>
              </Col>

              <Col md={12} style={{marginTop: 10}}>
                <FormGroup>
                  <Label for="formUserName">
                  <Trans i18nKey="form.username">Pseudonyme</Trans>
                  </Label>
                  <FormInput
                    id="formUserName"
                    placeholder="Username"
                    register={_register}
                    name="userName"
                    invalid={errors.userName}/>

                    <FormFeedback>
                      { errors.userName?.message }
                    </FormFeedback>   
                </FormGroup>
              </Col>
            </Row>
            <Row>
            <Col md={12}>
                <FormGroup>
                  <Label for="formEmail">
                  <Trans i18nKey="form.email">Email</Trans>
                  </Label>
                  <FormInput
                    id="formEmail"                    
                    placeholder="Email"
                    type="email"
                    register={_register}
                    name="email" 
                    invalid={errors.email}/>
                    <FormFeedback>
                      { errors.email?.message }
                    </FormFeedback>                       
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="formPassword">
                  <Trans i18nKey="form.password">Mot de passe</Trans>
                  </Label>
                  <FormInput
                    id="formPassword"
                    placeholder={t("form.password")}
                    type="password"
                    register={_register}
                    name="password" 
                    invalid={errors.password}/>
                    <FormFeedback>
                      { errors.password?.message }
                    </FormFeedback>                          
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="formConfirmPassword">
                   <Trans i18nKey="form.password_confirm">Mot de passe</Trans>
                  </Label>
                  <FormInput
                    id="formConfirmPassword"
                    type="password"
                    placeholder={t("form.password_confirm")}
                    register={_register}
                    name="confirmPassword" 
                    invalid={errors.confirmPassword}/>
                    <FormFeedback>
                      { errors.confirmPassword?.message }
                    </FormFeedback>                         
                </FormGroup>
              </Col>
            </Row>
            <FormGroup check>
              <FormInput
                id="formCheckCGU"
                name="checkCGU"
                type="checkbox"
                register={_register}

                invalid={errors.checkCGU}
              />
              <Label
                check
                for="formCheckCGU"
                style={{color: "#CBCFD2", marginBottom: 30}} >
                <Trans i18nKey="form.validate_terms">Validation des</Trans> <I18nLink to={"/terms"} target="_blank"><Trans i18nKey="form.terms_and_conditions">conditions générales de vente</Trans></I18nLink>
              </Label>
              <FormFeedback>
                      { errors.checkCGU?.message }
                </FormFeedback>   
            </FormGroup>  
            { getConfig().name === "blush" && <FormGroup check>
              <FormInput
                id="formOlderThan18"
                name="isOlderThan18"
                type="checkbox"
                register={_register}

                invalid={errors.isOlderThan18}
              />
              <Label
                check
                for="formOlderThan18"
                style={{color: "#CBCFD2", marginBottom: 30}} >
                <Trans i18nKey="form.validate_older_than_18">Je certifie avoir 18 ans ou plus</Trans>
              </Label>
              <FormFeedback>
                      { errors.isOlderThan18?.message }
                </FormFeedback>   
            </FormGroup> }
            <div className="text-center"><Button type="submit" color="primary">
              { props.submitLabel }
            </Button>
            </div>
            <p className="footer text-center" style={{color: "#CBCFD2", marginTop: 30}} >
            <Trans i18nKey="form.member">Are you a member?</Trans> <Link to={"/login"} style={{fontWeight: "bold"}}> <Trans i18nKey="form.login">Log in now</Trans></Link>
            </p>
        </Form>
}