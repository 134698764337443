import { Trans, useTranslation } from "react-i18next";
import { Page } from "../../../theme/templates/page";
import * as yup from "yup"
import { Button, Col, FormFeedback, FormGroup, Label, Row, Spinner } from "reactstrap";
import FormInput from "../../../theme/components/form-input";
import { Form } from "react-router-dom";
import { useAppSelector, useYupValidationResolver } from "../../../hooks";
import { useForm } from "react-hook-form";
import api from "../../../common/api";
import useModal from "../../../theme/hooks/use-modal";
import { useState } from "react";

type ContactFormValues = {
    name: string,
    email: string,
    subject: string,
    content: string
  }
  

export default function Contact() {
    const { t } = useTranslation(); 
    const user = useAppSelector((state) => state.login.user);
    const [sending, setSending] = useState(false); 

    const ContactFormSchema = yup.object({
        name: yup.string().label(t("contact.name")).min(2).required(),
        email: yup.string().label(t("contact.email")).email().required(),
        subject: yup.string().label(t("contact.subject")).min(2).required(),
        content: yup.string().label(t("contact.content")).min(2).required(),
    });

    const resolver = useYupValidationResolver(ContactFormSchema);

    const { register, handleSubmit,resetField, formState: { errors } } = useForm<ContactFormValues>({resolver, defaultValues: {name: user?.firstName != undefined && user.lastName != undefined ? user?.firstName + " " + user?.lastName : "", email: user?.email}});

    const onSubmit = (data: ContactFormValues) => {
        setSending(true);
        api.post("/contact", data).then((response) => {
            resetField("subject"); 
            resetField("content");
            modal.show(t("contact.messageSent.title"), <>{t("contact.messageSent.body")}</>, [{label: t("contact.messageSent.ok"), onClick: () =>{}}]);
            setSending(false);
        }).catch((error) => {
            modal.show(t("contact.messageNotSent.title"), <>{t("contact.messageNotSent.body")}</>, [{label: t("contact.messageNotSent.ok"), onClick: () =>{}}]);
            setSending(false);
        });
    }
    
    const modal = useModal();

    return <Page>
            <Row className="justify-content-center">
                { sending && <Spinner>
  
                </Spinner> }
            </Row>
            { !sending && <Form method="post" onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={{size: 8, offset:2}}>
                    <h1><Trans i18nKey={"contact.title"}>Contact</Trans></h1>

                        <FormGroup>
                        <Label for="formDate">
                            <Trans i18nKey={"contact.name"}>Name</Trans>
                        </Label>
                        <FormInput
                            name="name"
                            placeholder={t("contact.name")}
                            register={register}
                            type="text"
                            invalid={errors.name}
                        />
                        <FormFeedback>
                            { errors.name?.message }
                        </FormFeedback>
                        </FormGroup>
                        <FormGroup>
                        <Label for="formDate">
                            <Trans i18nKey={"contact.email"}>Email</Trans>
                        </Label>
                        <FormInput
                            id="formEmail"
                            name="email"
                            placeholder={t("contact.email")}
                            register={register}
                            type="email"
                            invalid={errors.email}
                        />
                        <FormFeedback>
                            { errors.email?.message }
                        </FormFeedback>
                        </FormGroup>

                        <FormGroup>
                        <Label for="formDate">
                            <Trans i18nKey={"contact.subject"}>Subject</Trans>
                        </Label>
                        <FormInput
                            id="formSubject"
                            name="subject"
                            placeholder={t("contact.subject")}
                            register={register}
                            type="text"
                            invalid={errors.subject}
                        />
                        <FormFeedback>
                            { errors.subject?.message }
                        </FormFeedback>
                        </FormGroup>
                        <FormGroup>
                        <Label for="formContent">
                            <Trans i18nKey={"contact.content"}>Content</Trans>
                        </Label>
                        <FormInput
                            id="formContent"
                            name="content"
                            placeholder={t("contact.content")}
                            register={register}
                            type="textarea"
                            invalid={errors.content}
                            style={{height:300}}
                        />
                        <FormFeedback>
                            { errors.content?.message }
                        </FormFeedback>
                        </FormGroup>
                        <Button color="primary" type="submit"><Trans i18nKey={"contact.send"}>Send</Trans></Button>
                    </Col>                                                            
                </Row>
            </Form>}
        </Page>
}