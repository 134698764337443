import React, { useEffect } from 'react';
import { Button, Container, Nav, Navbar, NavbarBrand, NavbarText } from 'reactstrap';


import { Footer } from '../components/footer';
import { useAppSelector } from '../../hooks';
import { Link, useLocation } from 'react-router-dom';
import CreatorImage from '../../features/creator/components/creator-image';
import { Trans } from 'react-i18next';
import { CreateLiveLink } from '../../features/creator/components/create-live-link';
import "./page.css";
import { LoadingProvider } from '../contexts/loading-context';
import { getPlaformImage } from '../../platforms/config';
import { I18nLink } from '../components/I18nLink';
import { ValidationStatus } from '../../common/enums';
import { useHistoryContext } from '../contexts/history-context';
export const MAIN_COLOR = '#F84B5F';

const logo = getPlaformImage("logo.svg");
const logoNoText = getPlaformImage("logo-notext.svg");


export interface PageProps{
    children: React.ReactNode;
    className?: string;
    fluid?: boolean
}

export function Page(props: PageProps){
    const user = useAppSelector((state) => state.login.user);
    const location = useLocation();

    const { push } = useHistoryContext();

    useEffect(() => {
      if (location.pathname !== "/"){
          push(location.pathname);
      }
  }, [location]);

    return <>
    <Navbar
      className="navbar"
      color="#131015"
      dark
    >
      <NavbarBrand href="/" style={{fontSize: '25pt', fontWeight: 'bolder'}}>
        <img className="logo-desktop" src={logo} />
        <img className="logo-mobile" src={logoNoText} />
      </NavbarBrand>
      <Nav className="me-auto" navbar>

      </Nav>
      <Nav style={{alignItems:"center"}}>      
      { !user && <>
          <I18nLink to={"/login"} ><Button color="primary" style={{backgroundColor: MAIN_COLOR, borderColor: MAIN_COLOR,  marginRight: 10, marginLeft: 10}}><Trans i18nKey={"common.login"}>Login</Trans></Button></I18nLink>
          <I18nLink to={ "/create-profil" } ><Button color="primary" style={{backgroundColor: MAIN_COLOR, borderColor: MAIN_COLOR}}><Trans i18nKey={"common.sign_up"}>Sign UP</Trans></Button></I18nLink>
          </>
        }
      { user && <>
          
          <I18nLink to={!user.profile ? "/viewer/overview" : "/creator/overview" } style={{marginRight: 10, marginLeft: 10, textDecoration: "none"}}>
            <div style={{display: "flex", flexDirection: "row", alignContent: "center", alignItems: "center"}}>
              <div className="page-account" style={{textDecoration: "underline"}}><Trans i18nKey={"page.account"}>Account</Trans></div>
              <div className="page-creator-profile"><CreatorImage userName={user.userName} image={user.picture} style={{maxHeight: 50}} color={user.color} validationStatus={user.pictureValidationStatus}/></div>
              <div className="page-username" style={{marginLeft: 10, marginRight:10, alignContent:'center', display:'flex', justifyItems: "center", alignItems: 'center'}}>{user.userName}</div>
            </div>
          </I18nLink>
          { user.profile && <CreateLiveLink><Button color="primary" style={{backgroundColor: MAIN_COLOR, borderColor: MAIN_COLOR}}><Trans i18nKey={"common.create_live_button"}>Créer un live</Trans></Button></CreateLiveLink> }
        </>
      }
      </Nav>
    </Navbar>
    <Container fluid={props.fluid} className={`page ${props.className}`} style={{marginBottom: 40}}>
            {props.children}
    </Container>
  <Footer />
  </> 

}