import { Col, Row } from "reactstrap"
import { Form, Link, useNavigate } from "react-router-dom";
import { I18nLink } from "../../../theme/components/I18nLink"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faVideo } from "@fortawesome/free-solid-svg-icons"
import { Trans } from "react-i18next"
import { useAppSelector } from "../../../hooks"

export default () => {
    const lockCreatorOnly = useAppSelector((state) => state.app.config?.lockCreatorOnly);
    return <>
            <I18nLink to={!lockCreatorOnly ? "/viewer/create-profil": "#"}>
                <Row>
                        <Col xl={12} style={{height: 100, display: "flex", justifyContent: "center", alignContent: "center", justifyItems: "center", alignItems: "center", textAlign: "center", border: "1px solid #424242", margin: 10, borderRadius: 20, marginTop: "50px", fontWeight: 600, color: lockCreatorOnly ? "grey" : "" , position: "relative"}}>
                            <FontAwesomeIcon style={{marginRight: '10px'}} icon={faEye} /> <Trans i18nKey="signup.as_viewer">Devenir viewer</Trans>
                            { lockCreatorOnly && <div style={{position: "absolute", top: 0, right: 0, backgroundColor: "grey", color: "white", padding: 5, borderRadius: 5, fontSize: 10, fontWeight: 800}}><Trans i18nKey={"signup.unavailable"}></Trans></div> }
                        </Col>                        
                </Row>
            </I18nLink>
            <I18nLink to={"/creator/create-profil"}>
                <Row>
                    <Col xl={12} style={{height: 100, display: "flex", justifyContent: "center", alignContent: "center", justifyItems: "center", alignItems: "center", textAlign: "center", border: "1px solid #424242", margin: 10,  borderRadius: 20, fontWeight: 600}}><FontAwesomeIcon style={{marginRight: '10px'}} icon={faVideo} /><Trans i18nKey="signup.as_creator">Devenir créateur</Trans></Col>
                </Row>
            </I18nLink>
            <p className="footer text-center" style={{color: "#CBCFD2", marginTop: 30}} >
            <Trans i18nKey="form.member">Are you a member?</Trans> <Link to={"/login"} style={{fontWeight: "bold"}}> <Trans i18nKey="form.login">Log in now</Trans></Link>
            </p>
        </>
}