import React, { useContext, useEffect } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import "./dashboard.css";
import "./funnel.css";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ModalContext from '../contexts/modal-context';
import { getPlaformImage } from '../../platforms/config';
import { I18nLink } from '../components/I18nLink';
import { useHistoryContext } from '../contexts/history-context';

const logo = getPlaformImage("logo-notext.svg");

export const MAIN_COLOR = '#F84B5F';

export interface FunnelProps{
    title?: string;
    children?: React.ReactNode;
    left?: React.ReactNode;
    hideClose?: boolean;
}

export function Funnel(props: FunnelProps){
    const location = useLocation();

    const { myHistory } = useHistoryContext();
    const navigate = useNavigate();


    return <>
        <Container className='funnel'>
            <Row style={{marginTop: 20}}>
                <Col style={{textAlign: 'center'}}>
                    <I18nLink to={'/'}><img src={logo} alt="WeLive" className="logo" /></I18nLink>
                </Col>
            </Row>
            <Row style={{minHeight : '45em', marginTop: 20}}>
            {props.left && <Col md={12} lg={6} className="left-pane" style={{backgroundColor: "#1C1C1C", borderTopLeftRadius: 20, borderBottomLeftRadius: 20}}>
                    { props.left }
                </Col> }
                <Col className="funnel-right" style={{backgroundColor: "#272727", flex:1, position: "relative"}}>
                    <Row style={{justifyContent: 'center', height: "100%", padding: 50}}>
                        <Col md={10} style={{display: 'flex',  flexDirection: 'column'}}>
                            <h1>{props.title}</h1>
                            {props.children}
                        </Col>

                    </Row>
                    { !props.hideClose  && <a href="#" onClick={() => {
                            if (myHistory.length >= 1){
                                navigate(myHistory[myHistory.length - 1]);
                            } else {
                                navigate('/')
                            }
                        }
                    } style={{color: "8A8A8A", textDecoration: "none"}}><div className='close' style={{display: 'flex', alignItems: "center", justifyContent: 'center', position: "absolute", right: 30, top: 30, width: 25, height: 25, color:"#8A8A8A", textAlign: "center", borderRadius: 30, border: "1px solid #8A8A8A", }}>
                            X
                    </div></a>}
                </Col> 
                          
            </Row>
        </Container>
    </>
    
}