import React, { useEffect } from 'react';
import './App.css';
import {
  BrowserRouter,
  RouterProvider, useLocation,
} from "react-router-dom";
import router from './router';
import authService from './features/login/services/auth-service';
import { setUser } from './features/login/login-slice';
import { getConfig as getPlaformConfig } from './platforms/config';

import { getConfig, setLocation, setReady } from './features/app/app-slice';
import { listCategories } from './features/event/event-slice';
import { useAppDispatch, useAppSelector } from './hooks';
import logger from './common/logger';
import { ModalProvider } from './theme/contexts/modal-context';
import { AlertProvider } from './theme/contexts/alert-context';
import useGeoLocation from "react-ipgeolocation";
import { LoadingProvider } from './theme/contexts/loading-context';
import CookieConsent from "react-cookie-consent";
import { t } from 'i18next';
import OnlyAdults from './platforms/blush/components/OnlyAdults';
import moment from "moment/min/moment-with-locales";
import HistoryProvider from './theme/contexts/history-context';




const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID ?? "",
}




function App() {
  const dispatch = useAppDispatch();
  const ready = useAppSelector((state) => state.app.ready);
  const location = useGeoLocation();

  useEffect(() => {
    if (location) {
      const locale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
      moment.locale(locale);
      dispatch(setLocation(location.country));
    }
  },[location]);

  useEffect( () => {
    logger.info('=============== WEERLIVE =============== ');
    logger.info('=== App version  : 1.0.0               = ');
    logger.info('=== Git Commit   : XXXXX               = ');


    logger.info('First render of App component.');

    const user = authService.getCurrentUser();

    dispatch(listCategories());
    dispatch(getConfig());
    if (user) {
        authService.me().then( (response) => {
          dispatch(setUser({user: response.data, token: user.token}));
        }).catch( (error) => {
          logger.error('Error while fetching user', error);
          authService.logout();
        }).finally( () => {
          dispatch(setReady(true));
        })
    }else {
      dispatch(setReady(true));
    }
  }, [])

    

  return (
    <ModalProvider>
      <AlertProvider>
        <>
        { getPlaformConfig().name == "blush" && <OnlyAdults /> }
        <CookieConsent enableDeclineButton={true}
            acceptOnScroll={false}
            buttonText={t("cookies.accept")}
            onAccept={(acceptedByScrolling) => {
            }} declineButtonText={t("cookies.decline")} onDecline={() => { 
             }}>
            {t("cookies.message")}
          </CookieConsent>
          <HistoryProvider>
            <LoadingProvider>
              { ready ? 
              <RouterProvider router={router}></RouterProvider> : <></> }
          </LoadingProvider>
        </HistoryProvider>
        </>
      </AlertProvider>
    </ModalProvider>
  );
}

export default App;
