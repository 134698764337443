import i18next, { use } from "i18next";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { Input } from "reactstrap";
import i18n from "../../i18n";

export interface PhoneInputProps{
    register: any;
    setValue: any;
    getValues: any;
    name: string;
    [key: string]: any;
    onChange?: (e: any) => void;
}

export default function CustomPhoneInput(props: PhoneInputProps) {
    const  { register, name, ...rest } =  props;
    const { ref, ...registerField } = register(name, { onChange: (e: any) => {props.onChange && props.onChange(e)},});
    const [formValue, setFormValue] = useState("");
    
    useEffect(() => {
        props.getValues(name) && setFormValue(props.getValues(name));
    }, [props.getValues]);
    
    

    return <><PhoneInput country={i18next.language === "fr" ? 'fr' : 'us'} onChange={ ( value ) => {
            props.setValue(name, value);
        }} value={formValue}
     /> 
     <Input innerRef={ref} {...registerField} {...rest}  style={{display: "none"}}onChange={ ( event ) => {
            setFormValue(event.target.value);
            props.onChange && props.onChange(event);
     }} value={formValue} />
    </>
}