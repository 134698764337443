export default function Legal(){
    return <>
        
        <div style={{width:'80%', marginLeft:'10%'}}>

        <h1 style={{marginTop:'100px'}}>PRIVACY POLICY</h1>


<p>Blush.live</p>

<p>At Blush, protecting your personal data is a priority!</p>
<p>The purpose of this privacy policy is to provide you with information about how we process your personal data. You can rest assured that when collecting and managing your data, we are fully compliant with the French Data Protection Act of 1978 and the European General Data Protection Regulation (GDPR) of 27 April 2016.</p>

<h3>What is personal data?</h3>
<p>When using our platform, Blush.live (hereafter referred to as the "Site"), we may ask you to provide personal data in order to use our services (hereafter referred to as the "Services").</p>
<p>Personal data refers to any information that allows you to be identified as an individual.</p>

<h3>Which personal data is collected by Blush?</h3>
<p>During the use of our Services, we may collect certain information about you, including:</p>
<ul>
    <li>Email address</li>
    <li>Username</li>
    <li>Country of residence</li>
    <li>Photos</li>
    <li>Phone number</li>
    <li>IP address</li>
    <li>Chat messages</li>
    <li>First and last name</li>
    <li>Address</li>
    <li>Data related to transactions (payments and payouts)</li>
    <li>Billing information (for invoice generation upon payout requests)</li>
    <li>Identity verification documents (e.g., copy of your ID)</li>
    <li>Activity tracking using third-party tools</li>
</ul>

<p>Please note, payment information is processed exclusively by our payment service providers and not directly by us.</p>
<p>All data collected is necessary for the operation of our Services. You must be 18 or older to use Blush.live.</p>

<h3>Who is the data controller?</h3>
<p>The data controller is MV CAPITAL, a simplified joint-stock company, registered in the Trades & Companies Register of Nanterre under the number 820 511 913, with its registered office located at:</p>

<address>
    MV CAPITAL <br/>
    50 avenue des Champs Elysées, <br/>
    75008 Paris, France
</address>

<p>For any questions, you can contact us at:</p>
<p>Email: <a href="mailto:hello@blush.live">hello@blush.live</a></p>

<h3>1. The Use of Your Personal Data</h3>
<p>For what purposes are your data collected and used?</p>
<ul>
    <li>Managing your access to and usage of our Services</li>
    <li>Creating a user database for registered members and prospects</li>
    <li>Preparing business statistics and website visit data</li>
    <li>Personalizing responses to your requests</li>
    <li>Sending information or marketing materials</li>
    <li>Compliance with legal obligations</li>
    <li>Fraud detection and prevention of misuse of our Services, including illegal activity</li>
</ul>

<p>If you do not provide the necessary personal data, you may not be able to use certain features of our Site.</p>

<h3>2. Recipients of the Collected Data</h3>
<p>The following may have access to your personal data:</p>
<ol>
    <li>Our team</li>
    <li>Inspection services, such as auditors</li>
    <li>Our service providers, including but not limited to:</li>
    <ul>
        <li>Payment Processor</li>
        <li>Google Analytics (Analytics)</li>
        <li>Amazon Web Services (AWS) (Servers and hosting)</li>
        <li>Google (Advertising and Analytics)</li>
        <li>Zoom (Video chat)</li>
        <li>MongoDB (Database)</li>
        <li>Brevo (Email marketing and communication services)</li>
    </ul>
</ol>

<p>Your personal data will never be sold or exchanged with third parties. However, data may be shared with public authorities, legal officers, or debt collection services when required.</p>

<h3>3. Personal Data Retention Period</h3>
<p>The retention of your personal data is determined by the type of information:</p>
<ul>
    <li>User data is kept for the entire duration of your registration with our Services, plus:
        <ul>
            <li>Three (3) years after you unsubscribe, for marketing purposes, unless you opt out earlier.</li>
            <li>One (1) year following any request for access or rectification of your data.</li>
            <li>Five (5) years after unsubscription, for legal compliance and contractual proof.</li>
            <li>Thirteen (13) months for cookies.</li>
            <li>One (1) year for screenshots and chat logs, used solely for fraud detection.</li>
        </ul>
    </li>
    <li>Credit card data is securely stored by our payment processors for the duration of the transaction. We do not store your credit card details.</li>
</ul>

<h3>4. Security</h3>
<p>We have implemented necessary technical and organizational measures to ensure the security, integrity, and confidentiality of your personal data, both directly and through our service providers.</p>

<h3>5. Hosting</h3>
<p>Your data is stored on the servers of Amazon Web Services (AWS), located within the European Union.</p>

<h3>6. Transfer Outside the European Union</h3>
<p>Some of our service providers may process your data outside the European Union. We ensure that all transfers are conducted with appropriate safeguards, such as:</p>
<ul>
    <li>Standard contractual clauses approved by the European Commission.</li>
    <li>Adherence to the Privacy Shield, when applicable.</li>
</ul>

<h3>7. Cookies</h3>
<p>For more information on how we use cookies, please refer to our <a href="#cookie-policy">Cookie Policy</a>.</p>

<h3>8. Your Rights</h3>
<p>You have several rights regarding your personal data, including:</p>
<ul>
    <li>Right to information: We provide this through our privacy policy.</li>
    <li>Right of access: You can request access to your personal data at any time.</li>
    <li>Right to rectification: You can update or correct your data if it is inaccurate or outdated.</li>
    <li>Right to restrict processing: Under certain circumstances, you can request a limitation on the processing of your data.</li>
    <li>Right to data portability: You can request your personal data in a machine-readable format.</li>
    <li>Right to be forgotten: You can request the deletion of your personal data.</li>
    <li>Right to object: You can object to the processing of your personal data, particularly for marketing purposes.</li>
    <li>Right to lodge a complaint: You can contact the relevant data protection authority if you believe your data has been misused (in France, this is the CNIL).</li>
</ul>

<p>To exercise any of these rights, you can contact us at:</p>
<p><a href="mailto:hello@blush.live">hello@blush.live</a></p>

<p>You may be required to provide proof of identity when submitting a request. If necessary, we may ask for additional information, such as a copy of an identity document.</p>

<h3>9. Effective Date</h3>
<p>This privacy policy takes effect from September 10, 2024.</p>

<h3>10. Changes to the Privacy Policy</h3>
<p>We reserve the right to modify this privacy policy at any time. Changes will be effective immediately upon publication on the Site. Your continued use of Blush.live after any updates implies your acceptance of the revised privacy policy.</p>

<h2 id="cookie-policy">Cookie Policy</h2>
<p>Blush.live</p>

<h3>What is a Cookie?</h3>
<p>A cookie is a small file stored on your browser or device when you visit our Site. Cookies allow us to recognize your browser and personalize your experience on future visits. We may also collect browsing data through cookies.</p>

<h3>Your Cookie Preferences</h3>
<p>Some cookies are necessary for the operation of the Site and do not require your consent. However, other cookies, such as those used for advertising, social media, or personalization, require your express consent.</p>

<p>You can manage your cookie preferences in your browser settings. For instructions on managing cookies in different browsers, please refer to the following resources:</p>
<ul>
    <li><a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">Internet Explorer</a></li>
    <li><a href="https://support.apple.com/en-us/guide/safari/sfri11471/mac">Safari</a></li>
    <li><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">Firefox</a></li>
</ul>

</div>
    </>
}