import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import { ScrollRestoration, Outlet, useNavigation, useParams, useNavigate, useLocation } from "react-router-dom";
import { getConfig } from "./platforms/config";
import { useHistoryContext } from "./theme/contexts/history-context";


export default function Root() {
    const navigate = useNavigate();


    const { t, i18n } = useTranslation()
    const {lang} = useParams();

    const getLanguage = () : string => i18n.language || window.localStorage.i18nextLng
    const platform = getConfig()
    
    // useEffect(() => {

    //     if (platform.name === "weerlive" && lang === "fr") i18n.changeLanguage("fr")
    //     else if (!lang) { 
    //         if (platform.name == "weerlive" && getLanguage().startsWith("fr")) {
    //             navigate("/fr" + location.pathname + location.search)
    //         }
    //         i18n.changeLanguage("en")
    //     }

    // }, [lang]);

    useEffect(() => {
        if (lang === "fr") i18n.changeLanguage("fr")
        else if (!lang) {
            if (getLanguage().startsWith("fr")) {
                navigate("/fr" + location.pathname + location.search)
            }
            i18n.changeLanguage("en")
        }
    }, [lang]);

    return (<>
            <Outlet  />
            <ScrollRestoration />
        </>
    );
}
