import { NavLink } from "react-router-dom"
import UserDTO from "../../login/dto/user.dto"
import { EventDTO, EventState } from "../dto/event.dto"
import { Button } from "reactstrap"
import { useAppSelector } from "../../../hooks";
import { useEffect, useState } from "react";
import moment from "moment/min/moment-with-locales";

import { User } from "../../login/login-slice";

import "./button-cta-event.css"
import { Trans } from "react-i18next";
import { I18nLink } from "../../../theme/components/I18nLink";

export interface ButtonCTAEventProps{
    event: EventDTO,
    user?: User,
}

export default function ButtonCTAEvent(props: ButtonCTAEventProps){
    const config = useAppSelector((state) => state.app.config);
    const [studioReady, setStudioReady] = useState(false);

    const checkStudioReady = () => {
        const studioReady = moment(props.event?.date).subtract(config?.studioAvailableBefore || 10, 'minutes').isBefore(moment());
        setStudioReady(studioReady);
        console.log("studio ready ?" + props.event.date + " " + studioReady);
    }

    useEffect(() => {
        let intervalRef : NodeJS.Timer | undefined;
        if (props.event && config){
            checkStudioReady();
            
            intervalRef = setInterval( ( ) => {
                checkStudioReady();

            }, 1000)
        }

        return () => {
            clearInterval(intervalRef);
        }

    }, [props.event, config]);

    return <div className="button-cta-event">{
        props.user && !props.event.isOwner && !props.event.isSubscribed && <I18nLink to={`/event/${props.event.shortId}/checkout`}><Button color="primary" style={{marginTop: 50, width: '100%'}}><Trans i18nKey={"event.cta.buy"}>Acheter</Trans></Button></I18nLink>}
        {props.user && studioReady && !props.event.isOwner && props.event.isSubscribed && props.event.state !== EventState.Vod && <I18nLink to={`/studio/${props.event.shortId}`}><Button color="primary" style={{marginTop: 50, width: '100%'}}><Trans i18nKey={"event.cta.join_studio"}>Rejoindre le studio</Trans></Button></I18nLink>}
        {props.user && !props.event.isOwner && props.event.isSubscribed && props.event.state === EventState.Vod && <I18nLink to={`/vod/${props.event.shortId}`}><Button color="primary" style={{marginTop: 50, width: '100%'}}><Trans i18nKey={"event.cta.watch_vod"}>Voir la VOD</Trans></Button></I18nLink>}
        {!props.user && !props.event.isSubscribed && props.event.state === EventState.Vod && <I18nLink to={`/event/${props.event.shortId}/checkout`}><Button color="primary" style={{marginTop: 50, width: '100%'}}><Trans i18nKey={"event.cta.buy_vod"}>Acheter la VOD</Trans></Button></I18nLink>}
        {!props.user && !props.event.isSubscribed && !props.event.isFull && props.event.state !== EventState.Vod && <I18nLink to={`/event/${props.event.shortId}/checkout`}><Button color="primary" style={{marginTop: 50, width: '100%'}}><Trans i18nKey={"event.cta.register_live"}>S'inscrire au live</Trans></Button></I18nLink>}

    </div>
}