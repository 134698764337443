import React, { useEffect } from "react";
import { useAppSelector } from "../../../hooks";
import { listUsers, User } from "../../login/login-slice";
import { Button } from "reactstrap";
import api from "../../../common/api";
import { ValidationStatus } from "../../../common/enums";

export default function AdminValidateUsers() {


    const [user, setUser] = React.useState<User | null>(null);
    const [loading, setLoading] = React.useState<boolean>(true);
    useEffect(() => {
        api.get<User>(`users/admin/picture`).then((response) => {
            setLoading(false);
            setUser(response.data);
        });
    }, []);

    function validateUser(id: string, validated: boolean){
        api.post(`users/admin/picture/${id}`, {
            status: validated ? ValidationStatus.Validated : ValidationStatus.Refused
        }).then((e) => {
            setUser(e.data);
        });
    }

  return (
    <>
        { loading ? <div>Chargement...</div> : <>

        <img style={{maxHeight: 400}} src={user?.picture} alt={user?.userName} />
        
        <div>
            <Button color="primary" onClick={ () => {
                validateUser(user!._id, true);
            }}>Valider</Button>
            <Button color="primary" onClick={ () => {
                validateUser(user!._id, false);
            }}>Arrêter</Button>

        </div></> }
    </>)
}