import { Button, Col, Form, FormFeedback, FormGroup, Label, Row } from "reactstrap";
import FormInput from "../../../theme/components/form-input";
import { Trans } from "react-i18next";
import { useForm } from "react-hook-form";

import * as yup from "yup"
import { useYupValidationResolver } from "../../../hooks";
import { useMemo, useState } from "react";
import countryList from "react-select-country-list";
import { CompanyDetails } from "../../login/login-slice";
import { t } from "i18next";

interface BankAccountHolderProps {
    onSubmit: (data: BankAccountHolderData) => void   
    onPrevious : () => void,
    details? : BankAccountHolderData;
}

export type BankAccountHolderData = {
    owner: string,
    country: string
    address: string,
    postCode: string,
    city: string,
}
  


const CompanyInformationsValidationSchema = yup.object({
    owner: yup.string().min(2).required(t("form.owner_required")),
    country: yup.string().label(t("form.bank_account_country")).min(0).required(),
    address: yup.string().required(t("form.address_required")),
    postCode: yup.string().required(t("form.post_code_required")),
    city: yup.string().min(2).required(t("form.city_required")),
});

export default function BankAccountHolder(props: BankAccountHolderProps) {


    const countries = useMemo(() => countryList().getData(), [])
    const resolver = useYupValidationResolver(CompanyInformationsValidationSchema)
    const { register: _register, handleSubmit, formState: { errors } } = useForm<BankAccountHolderData>({resolver, defaultValues: props.details})

    return  <Form method="post" onSubmit={handleSubmit(props.onSubmit)} style={{marginBottom: 20}}>
    {/* <h1 style={{fontSize: 22, marginTop: 20}}>Identité</h1> */}
        <Row>
            <Col md={12}>
                <FormGroup>
                <Label for="formAccountHolder">
                    <Trans i18nKey="form.account_holder">
                        Propriétaire du compte
                    </Trans>
                </Label>
                <FormInput
                    id="formAccountHolder"
                    placeholder={t("form.account_holder")}
                    register={_register}
                    name="owner" 
                    invalid={errors.owner}/>                  
                    <FormFeedback>
                    { errors.owner?.message }
                    </FormFeedback>                               
                </FormGroup>                             
            </Col>
            <Col md={12}>
                <FormGroup>
                    <Label for="formCountry">
                        <Trans i18nKey="form.bank_account_country">
                            Pays du compte en banque
                        </Trans>
                    </Label>
                    <FormInput
                        id="formCountry"
                        placeholder={t("form.bank_account_country")}
                        register={_register}
                        name="country"
                        type="select" 
                        invalid={errors.country}>
                    
                    { countries.map((country) => {
                        return <option key={country.value} value={country.value}>{country.label}</option>
                      })}
                    </FormInput>                  
                        <FormFeedback>
                        { errors.country?.message }
                        </FormFeedback>                               
                </FormGroup>        
                <FormGroup>
                    <Label for="formAddress">
                        <Trans i18nKey="form.address">
                            Addresse
                        </Trans>
                    </Label>
                    <FormInput
                        id="formAddress"
                        placeholder={t("form.address")}
                        register={_register}
                        name="address" 
                        invalid={errors.address}/>                  
                        <FormFeedback>
                        { errors.address?.message }
                        </FormFeedback>                               
                </FormGroup> 
                <FormGroup>
                    <Label for="formPostCode">
                        <Trans i18nKey="form.postCode">
                            Code postal
                        </Trans>
                    </Label>
                    <FormInput
                        id="formPostCode"
                        placeholder={t("form.postCode")}
                        register={_register}
                        name="postCode" 
                        invalid={errors.postCode}/>                  
                        <FormFeedback>
                        { errors.postCode?.message }
                        </FormFeedback>                               
                </FormGroup>         
                <FormGroup>
                    <Label for="formAddress">
                        <Trans i18nKey="form.city">
                            Ville
                        </Trans>
                    </Label>
                    <FormInput
                        id="formCity"
                        placeholder={t("form.city")}
                        register={_register}
                        name="city" 
                        invalid={errors.city}/>                  
                        <FormFeedback>
                        { errors.city?.message }
                        </FormFeedback>                               
                </FormGroup>                                                             
                <Button onClick={props.onPrevious} style={{marginRight: 10}}>{t("form.previous")}</Button>
                <Button color="primary" type="submit">{t("common.next")}</Button>
                                                                   
            </Col>                        
        </Row>
      </Form>;
}
