import { Button, Card, CardBody, CardImg, CardImgOverlay, CardText, CardTitle, Col, Row } from "reactstrap";
import "./event-card.css";
import moment from "moment/min/moment-with-locales";

import EventImage from "./event-image";
import { EventDTO, EventPaymentType, EventState } from "../dto/event.dto";
import CreatorImage from "../../creator/components/creator-image";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../hooks";
import CreatorHeader from "../../creator/components/creator-header";
import { I18nLink } from "../../../theme/components/I18nLink";
import { ValidationStatus } from "../../../common/enums";


interface EventCardProps{
    event: EventDTO;
}

export function EventCard(props: EventCardProps){ 
    const user = useAppSelector((state) => state.login.user);
    return (<Card className="event-card">
      <EventImage title={props.event.title} event={props.event} image={props.event.picture} color={props.event.color} validationStatus={props.event.pictureValidationStatus} />
      <CardBody>
        <Row>
          <Col md={2} xs={2}>
          <I18nLink to={`/profil/${props.event.owner.userName}`}><CreatorImage isLive={props.event.owner.profile && props.event.owner.profile.isLive} userName={props.event.owner.userName} image={props.event.owner.picture} color={props.event.owner.color} /></I18nLink>
          </Col>
          <Col md={10} xs={10}>
            <CardTitle tag="h5" >{props.event.title.substring(0, 75)} {props.event.title.length > 75 && "..." }</CardTitle>
            <I18nLink to={`/profil/${props.event.owner.userName}`}><h3>{props.event.owner.userName}</h3></I18nLink>
          </Col>
        </Row>
      </CardBody>
    </Card>);
}