import { CSSProperties } from "react";
import BASE_URL from "../../../common/urls";
import "./creator-image.css";
import { styles } from "./date-filter";
import { Trans } from "react-i18next";
import { ValidationStatus } from "../../../common/enums";

interface CreatorImageProps{
    image?: string;
    userName: string;
    style?: CSSProperties
    backgroundColor?: string;
    isLive?: boolean;
    color?: string;
    validationStatus?: ValidationStatus;
} 

export default function CreatorImage(props: CreatorImageProps){
    return <div className={`creator-image-container`} style={{backgroundColor: props.color, backgroundImage: props.image ? `url(${props.image})` : undefined, ...props.style}}  >
        { !props.image && <h1 style={{margin:0, padding:0}}>{props.userName.substring(0,1).toUpperCase()}</h1> }
        { props.image && props.validationStatus && props.validationStatus !== ValidationStatus.Validated && <div className="not-validated-indicator">
            {props.validationStatus === ValidationStatus.Pending  && <Trans i18nKey={"user_profile.validation_in_progress"}>Validation en cours</Trans>}
            {props.validationStatus === ValidationStatus.Refused && <Trans i18nKey={"user_profile.validation_rejected"}>Photo rejetée</Trans>}
        </div> }

    </div>
        
}