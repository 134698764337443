import React, { useEffect } from "react";

import { Button } from "reactstrap";
import { EventDTO } from "../../event/dto/event.dto";
import api from "../../../common/api";
import { ValidationStatus } from "../../../common/enums";

export default function AdminValidateEvents() {

    const [event, setEvent] = React.useState<EventDTO | null>(null);
    const [loading, setLoading] = React.useState<boolean>(true);
    useEffect(() => {
        api.get<EventDTO>(`events/admin/picture`).then((response) => {
            setLoading(false);
            setEvent(response.data);
        });
    }, []);

    function validateEvent(id: string, validated: boolean){
        api.post(`events/admin/picture/${id}`, {
            status: validated ? ValidationStatus.Validated : ValidationStatus.Refused
        }).then((e) => {
            setEvent(e.data);
        });
    }

  return (
    <>
        { loading ? <div>Chargement...</div> : <>

        <img style={{maxHeight: 400}} src={event?.picture} alt={event?.title} />
        
        <div>
            <Button color="primary" onClick={ () => {
                validateEvent(event!._id, true);
            }}>Valider</Button>
            <Button color="primary" onClick={ () => {
                validateEvent(event!._id, false);
            }}>Arrêter</Button>

        </div></> }
    </>
  );
}