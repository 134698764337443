import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import api from '../../common/api'
import { stat } from 'fs'

interface AppState {
    ready: boolean,
    message: string | undefined
    location: string | undefined,
    config?: {
      priceMinEvent: number,
      priceMaxEvent: number,
      priceMinReplay: number,
      affiliationDuration: number,
      affiliationPercent: number,
      commissionPercent: number,
      eventMaxDuration: number,
      studioAvailableBefore: number,
      lockCreatorOnly: boolean,
      recaptchaSiteKey: string,
      maxLiveUsers: number,
    }
}

const initialState = { 
    ready: false,
    message: undefined,
    config: undefined
 } as AppState


export const getConfig = createAsyncThunk('weerlive/config', async () => {
  const response = await api.get(`weerlive/config`)
  return response.data
});


const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setReady(state, action: PayloadAction<boolean>) {
      state.ready = action.payload
    },
    setMessage(state, action: PayloadAction<string>) {
      state.message = action.payload
    },
    setLocation(state, action: PayloadAction<string>) {
      state.location = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getConfig.fulfilled, (state, action) => {
      state.config = action.payload;
    });
  }
})

export const { setReady, setMessage, setLocation} = appSlice.actions
export default appSlice.reducer