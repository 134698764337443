import { useEffect } from "react";
import { Trans } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col } from "reactstrap";

export default function Validation() {
    const navigation = useNavigate();
    let { id } = useParams();

    return <>
        <p><Trans i18nKey={"signup.email_verif"}>We have sent you a verification e-mail.<br/><br/>
            Please verify your account via the link in the e-mail and complete registration.<br/><br/>
            If you don’t receive an email from us, please check your spam folder or contact customer support..</Trans></p>
        <Col style={{marginTop: 20}}>
            <Button color="primary" onClick={() => navigation("/")}><Trans i18nKey={"signup.back_home"}>Back to homepage</Trans></Button>
        </Col>
    </>
  }