import { createContext, useContext, useState } from "react";

const HistoryContext = createContext({
    myHistory: [] as string[],
    push: (location: string) => {},
});

export default function HistoryProvider({ children } : { children: React.ReactNode }) {
  const [myHistory, setMyHistory] = useState<string[]>([]);

  const push = (location: string) => setMyHistory([...myHistory, location]);

  return (
    <HistoryContext.Provider value={{ myHistory, push }}>
      {children}
    </HistoryContext.Provider>
  );
}

export const useHistoryContext = () => useContext(HistoryContext);