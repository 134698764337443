import React, { useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form"
import { useAppDispatch, useAppSelector, useYupValidationResolver } from "../../../hooks";
import * as yup from "yup"
import authService from "../../login/services/auth-service";
import { useNavigate } from "react-router-dom";
import CreateForm, { CreateProfil } from "../components/create-form";
import { t, use } from "i18next";
import { GoogleReCaptcha, GoogleReCaptchaProvider } from "react-google-recaptcha-v3";


export default function CreateViewer() {
  const config = useAppSelector((state) => state.app.config);
  const dispatch = useAppDispatch();
  const [error, setError] = useState<string | undefined>(undefined);
  const navigation = useNavigate();
  const lockCreatorOnly = useAppSelector((state) => state.app.config?.lockCreatorOnly);
  const [token, setToken] = useState<string>();
  useEffect(() => {
    if (lockCreatorOnly){
      navigation("/create-profil"); 
    }
  }, [lockCreatorOnly]);

  const handleVerify = React.useCallback( (token: string) => {
   setToken(token);
  }, []);

  const onSubmit: SubmitHandler<CreateProfil> = (data) => {
      authService.register(data.country, data.userName, data.email, data.password, token ?? "" ).then( (response) => {
        if (response.status === 200 || response.status === 201) {
          navigation("/validation");
        }else {
          setError(response.data.message);
        }
      }).catch( (error) => {
        console.log(error);
        setError(error.response.data.message);
      });

  }

  return <GoogleReCaptchaProvider reCaptchaKey={config?.recaptchaSiteKey ?? ""}>
    <GoogleReCaptcha onVerify={handleVerify} />
      <CreateForm onSubmit={onSubmit} error={error} submitLabel={t("form.confirm")} />
    </GoogleReCaptchaProvider>;
}