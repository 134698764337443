import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { Cropper, ReactCropperElement } from "react-cropper";
import { useEffect, useRef, useState } from "react";
import "cropperjs/dist/cropper.css";
import { heicTo } from "heic-to"

interface ImageCropperModalProps {
    visible: boolean;
    onCrop: Function;
    onCancel: Function;
    image: String;
    aspectRatio?: number;
}

export default function ImageCropperModal(props: ImageCropperModalProps) {
    const cropperRef = useRef<ReactCropperElement>(null);
    const [convertedImage, setConvertedImage] = useState<string | undefined>();
    const [loading , setLoading] = useState<boolean>(false);
    useEffect(() => {
        
        if (props.image !== null) {
            let image = new Image();
            image.src = props.image as string;
            image.onerror = (e) => {
                fetch(props.image as string)
                .then((res) => {
                    return res.blob();
                })
                .then((blob) =>{
                        if (blob.type === "image/heic") {
                            console.log(`Converting image`);
                            setLoading(true);
                            return heicTo({
                                blob: blob,
                                type: "image/jpeg",
                                quality: 0.5
                              })
                        }else {
                            throw new Error("Invalid image type");
                        }
                    }
                )
                .then((conversionResult) => {

                    setConvertedImage(URL.createObjectURL(conversionResult));
                    console.log(`Image converted`);
                    
                    setLoading(false);

                
                })
                .catch((e) => {
                    // see error handling section
                });
                console.log(e)
            }
            image.onload = () => {
                console.log(`Image loaded`);
                setConvertedImage(props.image as string);
                

            }
        }


        
      
    }, [props.image]);

    const cropImage = () => {
        if (cropperRef.current) {

            cropperRef.current.cropper.getCroppedCanvas().toBlob((blob: Blob | null) => {
                if (blob) {
                    props.onCrop(blob);
                }
            }, "image/jpeg", 0.5);
        }
    }

    return <Modal size="xl" isOpen={props.visible} toggle={() => { props.onCancel() }}>
        <ModalHeader toggle={() => {}}>Crop Image</ModalHeader>
        <ModalBody style={{display: "flex", flex: 1}}>
        {loading && <Spinner />}
        { !loading && <Cropper
                ref={cropperRef}
                style={{ height: 400, width: "100%" }}
                initialAspectRatio={props.aspectRatio || 1}
                aspectRatio={props.aspectRatio || 1}
                preview=".img-preview"
                src={convertedImage as string}
                viewMode={1}
                guides={true}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                checkOrientation={true} 
            /> }
        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={() => {
                cropImage();
            }}>Ok</Button>{' '}
            <Button color="secondary" onClick={() => {
                props.onCancel();
            }}>Cancel</Button>
        </ModalFooter>
    </Modal>
}