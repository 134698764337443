import { Form, Link, useNavigate } from "react-router-dom";
import FormInput from "../../../theme/components/form-input";
import { Alert, Button, Col, FormFeedback, FormGroup, Label, Row } from "reactstrap";
import * as yup from "yup"
import { useAppSelector, useYupValidationResolver } from "../../../hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "react-i18next";
import i18next, { t } from "i18next";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import ImageCropperModal from "../../../theme/components/image-cropper-modal";
import { useState } from "react";

interface CompleteProfilProps {
    onSubmit?: SubmitHandler<CompleteProfilSchema>;
    error?: string;
    submitLabel: String;
    channelName?: string;
    firstName?: string;
    lastName?: string;
}

export type CompleteProfilSchema = {

    firstName: string,
    lastName: string,
    channelName?: string;
    category: string,
    photo?: File,
  }
  
  const CreateProfilValidationSchema = yup.object({
    firstName: yup.string().min(2).label(t("complete_profil.fist_name")).required(),
    lastName: yup.string().min(2).label(t("complete_profil.last_name")).required(),
    channelName: yup.string().min(2).label(t("form.channel_name")).notRequired(),
    category: yup.string().label(t("complete_profil.category")).required(),
    photo: yup.mixed().notRequired()
  })
  

export default function CompleteProfilForm(props: CompleteProfilProps){
    const resolver = useYupValidationResolver(CreateProfilValidationSchema)
    const { register: _register, handleSubmit, formState: { errors } } = useForm<CompleteProfilSchema>({resolver, defaultValues: {firstName: props.firstName, lastName: props.lastName}});
    const navigation = useNavigate();
    const categories = useAppSelector((state) => state.events.categories);
    const [showEditor, setShowEditor] = useState(false);
    const [cropperThumbnailURL, setCropperThumbnailURL] = useState<String>("");
    const [cropperImage, setCropperImage] = useState<File | undefined>();

    const onCrop = (blob: Blob) => {
      let file = new File([blob], "file.png", {type:"image/png"});
      setCropperImage(file);
      setShowEditor(false);
    }

    return <Form method="post" onSubmit={handleSubmit(( data) =>{
            if (props.onSubmit){
              data.photo = cropperImage;
              props.onSubmit(data)

            };

        }) 
      
      } style={{display: "flex", flexDirection:"column", flex: 1, justifyContent: "space-evenly"}}>
            <Row>
              { props.error && <Alert color="danger">{props.error}</Alert> }  
              <Col md={6}>
                <FormGroup>
                  <Label for="formFirstName">
                  <Trans i18nKey={"complete_profil.fist_name"}>First Name</Trans>
                  </Label>
                  <FormInput
                    id="formFirstName"
                    placeholder={t("complete_profil.fist_name")}
                    register={_register}
                    name="firstName"
                    invalid={errors.firstName}/>

                    <FormFeedback>
                      { errors.firstName?.message }
                    </FormFeedback>   
                </FormGroup>
              </Col>
            <Col md={6}>
                <FormGroup>
                  <Label for="formLastName">
                  <Trans i18nKey={"complete_profil.last_name"}>Last Name</Trans>
                  </Label>
                  <FormInput
                    id="formLastName"                    
                    placeholder={t("complete_profil.last_name")}
                    type="text"
                    register={_register}
                    name="lastName" 
                    invalid={errors.lastName}/>
                    <FormFeedback>
                      { errors.lastName?.message }
                    </FormFeedback>                       
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="formChannelName">
                    <Trans i18nKey={"form.channel_name"}>Nom de la chaine</Trans>
                  </Label>
                  <FormInput
                    id="formLastName"                    
                    placeholder={t("form.channel_name")}
                    type="text"
                    register={_register}
                    name="channelName" 
                    invalid={errors.channelName}/>
                    <FormFeedback>
                      { errors.channelName?.message }
                    </FormFeedback>        
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="formPassword">
                    <Trans i18nKey={"complete_profil.category"}>Category</Trans>
                  </Label>
                  <FormInput
                    id="formCategory"
                    placeholder={t("complete_profil.category")}
                    type="select"
                    register={_register}
                    name="category" 
                    invalid={errors.category}>
                      { categories.map( (value) => <option value={value._id}>{value.translations && i18next.language in value.translations ? value.translations[i18next.language] : value.name}</option>) }
                    </FormInput>
                    <FormFeedback>
                      
                      { errors.category?.message }
                    </FormFeedback>                          
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="formPhoto">
                    <Trans i18nKey={"complete_profil.picture_profil"}>Photo de profil</Trans>
                  </Label>
                  <FormInput
                    id="formPhoto"
                    placeholder={t("complete_profil.picture_profil")}
                    type="file"
                    register={_register}
                    name="photo" 
                    onChange={(e) => {
                      if (e.target.files && e.target.files.length > 0){
                        const url = URL.createObjectURL(e.target.files[0]);
                        setCropperThumbnailURL(url as string);
                        setShowEditor(true);
                      }
                    }}
                    invalid={errors.category}/>
                    <FormFeedback>
                      { errors.photo?.message }
                    </FormFeedback>                          
                </FormGroup>
                <p className="placeholder"><FontAwesomeIcon icon={faTriangleExclamation} /> <Trans i18nKey={"complete_profil.picture_profil_constraints"}>Taille recommandée : 500x500 (1:1)</Trans></p>
              </Col>
            </Row>            
            <div>
              <Button type="submit" color="primary">
                { props.submitLabel }
              </Button>
            </div>
            <ImageCropperModal onCancel={() => {
              setShowEditor(false);
            }} visible={showEditor} onCrop={(blob: Blob) => {
              onCrop(blob);
            }} image={cropperThumbnailURL} aspectRatio={1} />

        </Form>
}