import { Alert, Button, FormFeedback, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import * as yup from "yup"
import { SubmitHandler, useForm } from "react-hook-form";
import { deleteUser } from "../../login/login-slice";
import { useAppDispatch, useYupValidationResolver } from "../../../hooks";
import { Trans } from "react-i18next";
import FormInput from "../../../theme/components/form-input";
import authService from "../../login/services/auth-service";
import { useState } from "react";
import Logout from "../../login/pages/logout";

type DeleteAccountSettingsFormValues = {
    password: string,
  }
  
const DeleteAccountValidationSchema = yup.object({
    password: yup.string().min(2).required(),
})

interface DeleteAccountSettingsProps {
  show: boolean;
  toggle: () => void;
}

export function DeleteAccountModal(props: DeleteAccountSettingsProps){
    const dispatch = useAppDispatch();
    const [error, setError] = useState<string | null>(null); 
    const onSubmitDelete: SubmitHandler<DeleteAccountSettingsFormValues> = (data) => {
        authService.delete(data.password).then( () => {
            window.location.href = "/logout";
            props.toggle();
        }).catch( (error) => {
            setError(error.response.data.message);
        })
    }    

    const toggle = () => {
        setError(null);

        props.toggle();
    }

    const passwordResolver = useYupValidationResolver(DeleteAccountValidationSchema);
    const { register, handleSubmit, formState: { errors : errorPassword } } = useForm<DeleteAccountSettingsFormValues>({resolver: passwordResolver})

    return <Modal isOpen={props.show} style={{backgroundColor: '#131015', borderRadius: 50}}>
    <ModalHeader style={{backgroundColor: '#131015', border: 'none'}} toggle={toggle}><Trans i18nKey={"backoffice.creator.settings.delete_account_modal_title"}>Suppression de compte</Trans></ModalHeader>
    <ModalBody style={{backgroundColor: '#131015', border: 'none'}}>
      <Alert color="danger" isOpen={error !== null} toggle={() => setError(null)}>
        {error}
      </Alert>
      <Trans i18nKey={"backoffice.creator.settings.delete_account_modal_content"}>Étes-vous sûr de vouloir supprimer votre compte ? Cette action est irréversible, pour valider merci de saisir votre mot de passe.</Trans>
      <FormGroup>
        <FormInput
          id="formPassword"
          placeholder="Password"
          register={register}
          type="password"
          name="password" 
          invalid={errorPassword.password}/>
        <FormFeedback>
            { errorPassword.password?.message }
        </FormFeedback> 
      </FormGroup>
    </ModalBody>
    <ModalFooter style={{backgroundColor: '#131015', border: 'none'}}>
      <Button color="primary" onClick={() => {
        handleSubmit(onSubmitDelete)();
      }}>
        <Trans i18nKey={"form.confirm"}>Confirmer</Trans>
      </Button>{' '}
      <Button color="secondary" onClick={toggle}>
      <Trans i18nKey={"form.cancel"}>Annuler</Trans>
      </Button>
    </ModalFooter>
</Modal>
}