import React, { useEffect, useState, useCallback } from "react";
import { useAppDispatch } from "../../../hooks";
import { Form, FormGroup, Input, Label, Table } from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment/min/moment-with-locales";

import CreatorImage from "../../creator/components/creator-image";
import Loading from "../../../theme/components/loading";
interface User {
    _id: string;
    userName: string;
    email: string;
    country: string;
    picture: string;
    createdAt: string;
    color?: string;
    totalRevenue?: number;
    transactions?: number;
    profile?: {
      isLive?: boolean;
      liveCount: number;
    };
  }
  
  interface UserListProps {
    fetchUsers: (args: { page: number; limit: number; userName?: string; email?: string }) => any; // Action to fetch data
    data: User[];  // Array of users or creators
    page: number;  // Current page
    total: number;  // Total number of users
    limit: number;  // Limit of users per page
    loading: boolean;  // Loading state
    creator?: boolean;  // If true, the list will be for creators
    defaultUserName?: string;  // Default value for the username filter
    defaultEmail?: string;  // Default value for the email filter
  }

const UserList: React.FC<UserListProps> = ({
  fetchUsers, // Function to fetch users or creators
  data, // Users or creators data
  page, // Current page
  total, // Total number of users
  limit, // Limit per page
  loading, // Loading state
  defaultUserName = "", // Default username search value
  defaultEmail = "", // Default email search value
  creator = false, // If true, the list will be for creators
}) => {
  const [searchUserName, setSearchUserName] = useState(defaultUserName || "");
  const [searchEmail, setSearchEmail] = useState(defaultEmail || "");

  const dispatch = useAppDispatch();

  const loadMoreUsers = useCallback(() => {
    if (!loading && data.length < total) {
      dispatch(
        fetchUsers({
          page: page + 1,
          limit,
          userName: searchUserName,
          email: searchEmail,
        })
      );
    }
  }, [loading, data.length, total, page, limit, searchUserName, searchEmail, fetchUsers, dispatch]);

  const handleSearch = useCallback(() => {
    dispatch(
      fetchUsers({
        page: 1,
        limit,
        userName: searchUserName,
        email: searchEmail,
      })
    );
  }, [searchUserName, searchEmail, fetchUsers, dispatch]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  return (
    <div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSearch();
        }}
      >
        <FormGroup>
          <Label for="searchUserName">UserName</Label>
          <Input
            placeholder="UserName"
            value={searchUserName}
            onChange={(e) => setSearchUserName(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="searchEmail">Email</Label>
          <Input
            id="searchEmail"
            name="email"
            placeholder="Email"
            type="text"
            value={searchEmail}
            onChange={(e) => setSearchEmail(e.target.value)}
          />
        </FormGroup>
        <input type="submit" value="Rechercher" />
      </Form>

      {data && (
        <InfiniteScroll
          loader={<Loading />}
          next={loadMoreUsers}
          dataLength={data.length}
          hasMore={data.length < total}
        >
          <Table dark={true}>
            <thead>
              <tr>
                <th>Photo</th>
                <th>Username</th>
                <th>Email</th>
                { creator &&<>
                    <th>Lives</th>
                    <th>CA</th>
                  </>
                }   
               { !creator &&<>
                    <th>Transactions</th>
                    <th>Revenues</th>
                  </>
                }                   
                <th>Country</th>
              </tr>
            </thead>
            <tbody>
              {data.map((user) => (
                <tr key={user._id} className="secondary align-content-center">
                  <td>
                    <a href={user.profile ? `/profil/${user.userName}` : "#"} target={user.profile && "_blank"}><CreatorImage
                      image={user.picture}
                      userName={user.userName}
                      isLive={user.profile?.isLive}
                      color={user.color}
                    /></a>
                  </td>
                  <td>
                    <a href={user.profile ? `/profil/${user.userName}` : "#"} target="_blank"><p>{user.userName}</p></a>                  
                  </td>
                  <td>
                    <h1 style={{ color: "#8A8A8A", fontSize: 12, textTransform: "uppercase" }}>
                      {moment(user.createdAt).format("L LT")}
                    </h1>
                    <p style={{ fontSize: 12 }}>{user.email}</p>
                  </td>                  
                  { user.profile && <><td>
                    <p>{user.profile?.liveCount}</p>
                  </td>
                  <td>
                    <p>{user.totalRevenue?.toFixed(2)}</p>
                  </td></>}
                  { !user.profile && <><td>
                    <p>{user.transactions}</p>
                  </td>
                  <td>
                    <p>{user.totalRevenue?.toFixed(2)}</p>
                  </td></>}                  
                  <td>
                    <p>{user.country}</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </InfiniteScroll>
      )}
    </div>
  );
};

export default UserList;