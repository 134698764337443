import React, { useEffect, useState } from "react";
import "./profil.css";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { listEventsByCreator, listMyEvents, listNextEventByCategory } from "../../event/event-slice";
import {  useParams } from "react-router-dom";
import CreatorHeader from "../components/creator-header";
import { getCreatorByUserName } from "../creator-slice";
import { LiveSelector } from "../../../theme/components/live-selector";


export default function Profil() {
  const creator = useAppSelector((state) => state.creator.creator);
  const loading = useAppSelector((state) => state.events.loading);
  const events = useAppSelector((state) => state.events.events);
  const count = useAppSelector((state) => state.events.eventCount);
  const next = useAppSelector((state) => state.events.eventNext);
  const [filter, setFilter] = useState<string>("live");
  const [category, setCategory] = useState<string>("all");

  const dispatch = useAppDispatch();
  let { id } = useParams();



  useEffect(() => {
    if (id !== undefined){
      dispatch(getCreatorByUserName(id));
    }
  }, [id]);

  
  const categories = useAppSelector( (state) => state.events.categories) 

  

  useEffect(() => {
    if (creator !== null && creator !== undefined){
      dispatch(listEventsByCreator({id: creator._id, filter: "live", offset: 0}));
    }
  }, [id, creator]);

  
  return <>
  {creator && <><CreatorHeader creator={{...creator.profile, userName: creator.userName, picture: creator.picture, color: creator.color}} />
  <LiveSelector xl={4} hideCategories={true} categories={categories} states={[]} category={category} filter={filter} loading={loading} events={events} count={count} next={next} onCategoryChange={(category) => {
              { () => {}}
                (category);
                //dispatch(listEventsByCreator({id: creator._id, filter: category}));
            }} onStateChange={(filter) => {
                setFilter(filter);
                dispatch(listEventsByCreator({id: creator._id, filter: filter, offset: 0}));
            }} loadNext={(category, filter, offset) => {
                dispatch(listEventsByCreator({id: creator._id, filter: filter, offset}))
            }}/>
   
  
  </>}
  </>
}