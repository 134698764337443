import React, { useEffect, useMemo, useState } from "react";
import { Col, FormGroup, Row, Label, Input, Button, Form, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from "reactstrap";
import FormInput from "../../../theme/components/form-input";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector, useYupValidationResolver } from "../../../hooks";
import * as yup from "yup"
import { cleanMessage, deleteUser, update, updateCreatorProfil, updatePassword, uploadProfilePicture } from "../../login/login-slice";
import CreatorImage from "../../creator/components/creator-image";
import { Trans } from "react-i18next";
import countryList from "react-select-country-list";
import ImageCropperModal from "../../../theme/components/image-cropper-modal";
import { DeleteAccountModal } from "../containers/delete-account";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { ValidationStatus } from "../../../common/enums";
import { t } from "i18next";
import { userNameValidator } from "../../../common/validators";


type EditProfilFormValues = {
    userName: string,
    email: string,
    country: string,
}
  

const EditProfilValidationSchema = yup.object({
  userName: userNameValidator,
  email: yup.string().email().notRequired(),
  country: yup.string().required(),

})

const UpdatePasswordSchema = yup.object({
  password: yup.string().min(8).required(),
  confirmPassword: yup.string().min(8).required().oneOf([yup.ref('password')], 'Passwords must match'),
})


export type UpdatePasswordValues = {
  password: string,
  confirmPassword: string,
}

export default function EditProfil(){
    const resolver = useYupValidationResolver(EditProfilValidationSchema)
    const user = useAppSelector((state) => state.login.user);
    const categories = useAppSelector((state) => state.events.categories);

    const success = useAppSelector((state) => state.login.success);
    const error = useAppSelector((state) => state.login.error);
    const countries = useMemo(() => countryList().getData(), [])

    const [showEditor, setShowEditor] = useState(false);
    const [cropperThumbnailURL, setCropperThumbnailURL] = useState<String>("");

    const passwordResolver = useYupValidationResolver(UpdatePasswordSchema);
    const { register: _registerPassword, handleSubmit: handleSubmitPassword, formState: { errors : errorPassword } } = useForm<UpdatePasswordValues>({resolver: passwordResolver})

    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const toggle = () => setDeleteConfirm(!deleteConfirm);

    const { register: _register, handleSubmit,  formState: { errors } } = useForm<EditProfilFormValues>({resolver, defaultValues: {userName: user?.userName, email: user?.email, country: user?.country}})
    const dispatch = useAppDispatch();
    const onSubmit: SubmitHandler<EditProfilFormValues> = (data) => {
        console.log(data);
        dispatch(update({userName : data.userName, email: data.email, country: data.country}));
    }



    
 

    const onSubmitPassword: SubmitHandler<UpdatePasswordValues> = (data) => {
      dispatch(updatePassword(data.password));

   }
   
    const onCrop = (blob: Blob) => {
      let file = new File([blob], "file.png", {type:"image/png"});
      dispatch(uploadProfilePicture(file));
      setShowEditor(false);
    }


    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file){
        setCropperThumbnailURL(URL.createObjectURL(file));
        setShowEditor(true);
      }
    }


    const fileInput = React.useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (success || error){
        setTimeout(() => {
          dispatch(cleanMessage());
        }, 3000)
      }
    }, [success, error]);

    return <>
          { user && <>
            { success && <Alert color="success">
              <Trans i18nKey="backoffice.viewer.profile.updateSuccess">Paramètres modifiés avec succès</Trans>
            </Alert> }
            { error && <Alert color="danger">
              <Trans i18nKey="backoffice.viewer.profile.update_error">Erreur lors de la modification des paramètres :</Trans> {error}
            </Alert> }
            <DeleteAccountModal show={deleteConfirm} toggle={toggle} />
          <Form method="post" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="formLastName">
                    <Trans i18nKey="form.username">Pseudo</Trans>
                  </Label>
                  <FormInput
                    id="formPseudo"
                    placeholder="Pseudonyme"
                    register={_register}
                    name="userName" 
                    invalid={errors.userName}/>

                    <FormFeedback>
                      { errors.userName?.message }
                    </FormFeedback>                       
                </FormGroup>
                <FormGroup>
                  <Label for="formEmail">
                    <Trans i18nKey="form.email">
                      Email
                    </Trans>
                  </Label>
                  <FormInput
                    id="FormEmail"
                    placeholder="email"
                    register={_register}
                    name="email" 
                    invalid={errors.email}/>

                    <FormFeedback>
                      { errors.email?.message }
                    </FormFeedback>             
                </FormGroup>
                <FormGroup>
                <Label for="formCountry">
                  <Trans i18nKey="form.country">Pays</Trans>

                  </Label>
                    <FormInput
                      id="formCountry"
                      placeholder="Country"
                      type="select"
                      register={_register}
                      name="country"
                      invalid={errors.country}
                      style={{backgroundColor: "white"}}
                      defaultValue="FR"
                    >
                      { countries.map((country) => {
                        return <option key={country.value} value={country.value}>{country.label}</option>
                      })}
                    </FormInput>
                    <FormFeedback>
                      { errors.country?.message }
                    </FormFeedback>   
                    <Button type="submit" color="primary" style={{marginTop: 20}}>
                      <Trans i18nKey="form.save">Sauvegarder</Trans>
                    </Button>                           
                </FormGroup>     
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="formFirstName">
                    Photo
                  </Label>
                  <div className="profileContainer" style={{display: "flex", flexDirection: 'column', justifyContent: 'center', width: "50%", margin: "20px auto"}}>
                    <CreatorImage userName={user!.userName} image={user!.picture} color={user!.color} validationStatus={user.pictureValidationStatus}/> 
                    <input ref={fileInput} style={{visibility: "hidden"}} type="file" onChange={ onChange }/>
                    <p style={{color:'#8A8A8A', width: '100%', textAlign: 'center', marginTop: 10}}><FontAwesomeIcon icon={faTriangleExclamation} /> Recommanded size : 500x500 (1:1)</p>
                    <Button color="primary" style={{marginLeft: 20}} onClick={ () => {
                      fileInput.current?.click()
                    }}><Trans i18nKey="form.updateProfilPicture">Upload Photo</Trans></Button>
                  </div>
                </FormGroup>
              </Col>
           
            </Row>
           </Form> 
          <Form onSubmit={handleSubmitPassword(onSubmitPassword)} style={{marginTop: 46}}>
            <h1 style={{fontSize: 22}}><Trans i18nKey="form.accountManagement">Gestion de compte</Trans></h1>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="formLastName">
                    <Trans i18nKey="form.password">Mot de passe</Trans>
                  </Label>
                  <FormInput
                    id="formPassword"
                    placeholder="Password"
                    register={_registerPassword}
                    type="password"
                    name="password" 
                    invalid={errorPassword.password}/>
                  <FormFeedback>
                      { errorPassword.password?.message }
                  </FormFeedback> 
                </FormGroup>
               
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="formConfirmPassword">
                  <Trans i18nKey="form.passwordConfirm">Confirmer mon mot de passe</Trans>
                  </Label>
                  <FormInput
                    id="formConfirmPassword"
                    placeholder="Password"
                    register={_registerPassword}
                    type="password"
                    name="confirmPassword" 
                    invalid={errorPassword.confirmPassword}/>
                    
                <FormFeedback>
                    { errorPassword.confirmPassword?.message }
                </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <ImageCropperModal image={cropperThumbnailURL} onCrop={onCrop} onCancel={ () => {
              setShowEditor(false);
            }} visible={showEditor} />
            <Button type="submit" color="primary">
              Sauvegarder
            </Button>
            </Form>
            <Row style={{marginTop: 20}}>
              <Col md={12} style={{textAlign: 'center'}}>
                <a style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={ () => {
                  toggle();
                }}><Trans i18nKey="backoffice.user.delete_account">Supprimer mon compte</Trans></a>
              </Col>
            </Row>

            </>}
    </>
} 