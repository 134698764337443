import { useEffect, useRef } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
const Veriff = require('@veriff/js-sdk');
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk';

interface IDValidationProps{
    url: string;
    onDone: () => void;
    onDismiss: () => void;
}

export const IDValidation = (props: IDValidationProps) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    
    createVeriffFrame({
      url: props.url,
      onEvent: function(msg) {
        switch(msg) {
          case MESSAGES.CANCELED:
              props.onDismiss();
            break;
          case MESSAGES.FINISHED:
              props.onDone();
            break;
        }
      }
    });

    
    
  }, []);

  return <Modal isOpen={true} centered={true} backdrop={true} toggle={ () => {
    props.onDismiss();
  }}>
    <ModalBody>
    </ModalBody>
  </Modal>
};