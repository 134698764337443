import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks";
import useModal from "../../../theme/hooks/use-modal";
import React from "react";
import { t } from "i18next";
import { I18nLink } from "../../../theme/components/I18nLink";
import { Trans } from "react-i18next";
import { IdentityValidationState, ValidationStatus } from "../../../common/enums";
import { faInstagram, faTiktok, faXTwitter } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export interface CreateLiveLinkProps{
    children: React.ReactNode;
}

export function CreateLiveLink(props: CreateLiveLinkProps) {
    const user = useAppSelector((state) => state.login.user);
    const config = useAppSelector((state) => state.app.config);
    const navigate = useNavigate();
    const modal = useModal();

    return <I18nLink to={((user?.profile?.companyDetails === undefined || user?.profile?.identityValidationStatus !== IdentityValidationState.Validated) || config?.lockCreatorOnly) ? "" : "/creator/create-event"} onClick={ () => {

        if (user?.profile?.companyDetails === undefined || user?.profile?.identityValidationStatus !== IdentityValidationState.Validated){
            modal.show(t("backoffice.creator.profil_incomplete_modal.title"),  <Trans i18nKey={"backoffice.creator.profil_incomplete_modal.message"}></Trans>, [{label: t("backoffice.creator.profil_incomplete_modal.valid") , onClick: () => {
                navigate("/creator/settings");
            }}, {label: t("backoffice.creator.profil_incomplete_modal.cancel"), onClick: () => {
                
            }}]) 
        } else if (config?.lockCreatorOnly){
            modal.show(t("backoffice.creator.platform_locked.title"), <>{<Trans i18nKey={"backoffice.creator.platform_locked.message"} ></Trans>}<a style={{marginRight: '15px', marginLeft: '15px'}} href={t("platform.instagram")}><FontAwesomeIcon icon={faInstagram} /></a><a href={t("platform.twitter")}><FontAwesomeIcon icon={faXTwitter} /></a></>, [{label: t("backoffice.creator.platform_locked.valid") , onClick: () => {
              navigate("/creator/partners");
        }}])
        }
    }}>
        {props.children}
    </I18nLink>
}